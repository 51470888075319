import {Injectable} from "@angular/core";
import {lastValueFrom} from "rxjs";
import {HttpBackend, HttpClient, HttpHeaders} from "@angular/common/http";
import {Orderconfirmation} from "../models/orderconfirmation";
import {environment} from "../../../environments/environment";
import {Base64Mail} from "../models/base64Mail";
import {MessageService} from "primeng/api";

@Injectable({
    providedIn: 'root',
})
export class OrderConfirmationService {

    private httpClient: HttpClient;
    Reportresponse: any;
    base64data: Base64Mail = new Base64Mail();

    constructor(private handler: HttpBackend, private http: HttpClient, private messageService: MessageService) {
        this.httpClient = new HttpClient(handler);
    }

    listAllOrderConfirmations(): Promise<any> {
        const result$ = this.http.get<any>(environment.host + 'orderconfirmation');
        return lastValueFrom(result$);
    }

    listAllOrderConfirmationsWithFilter(filter: string): Promise<any> {
        const result$ = this.http.get<any>(environment.host + 'orderconfirmation', {params: {filter: filter}});
        return lastValueFrom(result$);
    }

    getOrderConfirmationById(id: string): Promise<any> {
        const result$ = this.http.get<any>(environment.host + 'orderconfirmation/' + id);
        return lastValueFrom(result$);
    }

    createOrderConfirmation(orderConfirmation: Orderconfirmation): Promise<any> {
        const result$ = this.http.post<any>(environment.host + 'orderconfirmation', orderConfirmation);
        return lastValueFrom(result$);
    }

    async createReport(report: any): Promise<any> {
        try {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': 'Basic ' + btoa("admin:GX2TXuL7ef6i5NS3b7SL"),
                    'Content-Type': 'application/json',
                }),
                responseType: 'blob' as 'json',  // Set responseType to 'blob'
            };
            const result$ = this.httpClient.post(environment.jsreportEndpoint + "/api/report", report, httpOptions);
            return await lastValueFrom(result$);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async connectAuthentication(credentials: { username: string, password: string }): Promise<string> {
        try {
            const result: any = await this.httpClient.post('https://connect-backend.platzer-wimmer.de/api/authentication/token', credentials).toPromise();
            if (result) {
                return result.accessToken;
            }
            return '';
        } catch (e) {
            console.error(e);
            return '';
        }
    }

    async connectCreateCall(data: any): Promise<any> {
        let username = environment.connectUsername;
        let password = environment.connectPassword;

        const token = await this.connectAuthentication({username: username, password: password});

        //TODO: add this token in header and create api car call

        // try {
        //     const result$ = this.httpClient.post("https://connect-backend.platzer-wimmer.de/api/Car", data);
        //     return await lastValueFrom(result$);
        // } catch (e) {
        //     console.error(e);
        //     throw e;
        // }
    }

    async sendOrderConfirmationEmail(attachment: Base64Mail): Promise<any> {
        const result$ = this.http.post<any>(environment.host + 'orderconfirmation/pdf', attachment);
        return lastValueFrom(result$);
    }

    async downloadAndSendOrderConfirmation(orderConfirmation: Orderconfirmation, download: boolean, send: boolean, createConnectPlaceholder: boolean) {
        try {
            const report = {
                data: {
                    userId: orderConfirmation.userId,
                    hideText: orderConfirmation.priceNetText,
                    salutation: orderConfirmation.salutation,
                    firstName: orderConfirmation.firstName,
                    lastName: orderConfirmation.lastName,
                    street: orderConfirmation.street,
                    zip: orderConfirmation.zip,
                    city: orderConfirmation.city,
                    country: orderConfirmation.country,
                    phone: orderConfirmation.phone,
                    mobile: orderConfirmation.mobile,
                    email: orderConfirmation.email,
                    vehicle: orderConfirmation.vehicle,
                    priceNet: orderConfirmation.priceNet.toFixed(2).replace('.', ','),
                    priceGross: orderConfirmation.priceGross.toFixed(2).replace('.', ','),
                    tax: orderConfirmation.tax.toFixed(2).replace('.', ','),
                    orderConfirmationDate: orderConfirmation.orderConfirmationDate,
                    sellerFirstName: orderConfirmation.sellerFirstName,
                    sellerLastName: orderConfirmation.sellerLastName,
                    sellerEmailAddress: orderConfirmation.sellerEmailAddress,
                },
                template: {
                    name: 'Auftragsbestaetigung',
                }
            }

            // Call createReport and wait for the response
            this.Reportresponse = await this.createReport(report);

            // Check if the response is a Blob (binary data)
            setTimeout(async () => {
                if (this.Reportresponse instanceof Blob) {
                    const blobUrl = URL.createObjectURL(this.Reportresponse);
                    const link = document.createElement('a');

                    if (send) {
                        await this.sendOrderConfirmationEmail(await this.convertToBase64(blobUrl, report.data));
                    }

                    if (download) {
                        link.href = blobUrl;
                        link.download = 'Auftragsbestaetigung-' + report.data.firstName + '_' + report.data.lastName + '.pdf';
                        link.click();
                    }
                } else {
                    console.error('Unexpected response type:', this.Reportresponse);
                }
            }, 4000);

            if (this.Reportresponse && createConnectPlaceholder) {
                const carData = await this.connectCreateCall(report.data);
                console.warn(carData);
            }

            this.messageService.add({
                severity: 'success',
                summary: 'Erfolg',
                detail: 'Der Auftrag wurde erfolgreich ausgeführt.',
            });
        } catch (e) {
            this.messageService.add({
                severity: 'error',
                summary: 'Fehler',
                detail: 'Es ist ein Fehler aufgetreten: ' + e,
            });
            console.error(e);
        }
    }

    async convertToBase64(url: string, report: any): Promise<Base64Mail> {
        this.http.get(url, { responseType: "blob" }).subscribe(blob => {
            const reader = new FileReader();
            const binaryString = reader.readAsDataURL(blob);
            reader.onload = (event: any) => {
                this.base64data.base64 = event.target.result.replace("data:application/pdf;base64,", "");
                this.base64data.subject = report.vehicle;
                this.base64data.receiverEmail = report.email;
                this.base64data.sellerEmail = report.sellerEmailAddress;
                return this.base64data;
            };

            reader.onerror = (event: any) => {
                console.log("File could not be read: " + event.target.error.code);
            };
        });
        return this.base64data;
    }
}
