import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getUserFromToken, GlobalConstants } from '../shared/models/global.constants';
import { MessageService } from 'primeng/api';
import { CurrentUser } from '../shared/models/currentUser';
import {AuthService} from "../auth/auth.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [MessageService],
})
export class DashboardComponent implements OnInit {
  public menuActive: boolean = true;
  public items: any[] = [];
  public currentUser: CurrentUser = getUserFromToken();

  constructor(
      private messageService: MessageService,
      private authService: AuthService,
      public router: Router
  ) {}

  public ngOnInit(): void {

  }

  public onMenuButtonClick(): void {
    GlobalConstants.sidebarActive = !GlobalConstants.sidebarActive;
    this.menuActive = GlobalConstants.sidebarActive;
  }

  public onMaskClick(): void {
    GlobalConstants.sidebarActive = false;
    this.menuActive = false;
  }

  public logout(): void {
    this.authService.logout();
  }
}

