import { Injectable } from '@angular/core';
import {FormType} from "../models/formType";
import {FormGroup} from "@angular/forms";
import {Contract} from "../models/contract";

@Injectable({
  providedIn: 'root'
})
export class PatchService {

  constructor() {
  }

  async applyValues(contractForm: FormGroup, vatReportable: boolean, formType: FormType, contractId?: string): Promise<Contract> {
    const contractData = Object.assign(contractForm.value);
    let contract = new Contract();

    contract.contracttype = contractData.contracttype;
    contract.vehicle = contractData.vehicle;
    contract.buyersalutation = contractData.buyersalutation;
    contract.buyerfirstname = contractData.buyerfirstname;
    contract.buyerlastname = contractData.buyerlastname;
    contract.buyerstreet = contractData.buyerstreet;
    contract.buyerzip = contractData.buyerzip;
    contract.buyercity = contractData.buyercity;
    contract.buyerphone = contractData.buyerphone;
    contract.buyermobile = contractData.buyermobile;
    contract.buyeremail = contractData.buyeremail;
    contract.brand = contractData.brand;
    contract.equipment = contractData.equipment;
    contract.engine = contractData.engine;
    contract.color = contractData.color;
    contract.interior = contractData.interior;
    contract.tires = contractData.tires;
    contract.vin = contractData.vin
    contract.deliverydate = contractData.deliverydate;
    contract.contractdate = contractData.contractdate;
    contract.vehiclepricenet = contractData.vehiclepricenet;
    contract.vehiclepricegross = contractData.vehiclepricegross;
    contract.discountnet = contractData.discountnet;
    contract.discountgross = contractData.discountgross;
    contract.transfernet = contractData.transfernet;
    contract.transfergross = contractData.transfergross;
    contract.endpricenet = contractData.endpricenet;
    contract.endpricegross = contractData.endpricegross;
    contract.paymenttype = contractData.paymenttype;
    contract.formtype = formType;
    contract.mileage = contractData.mileage;
    contract.firstregistration = contractData.firstregistration;
    contract.mainexaminationemissiontest = contractData.mainexaminationemissiontest;
    contract.previousowner = contractData.previousowner;
    contract.vatReportable = vatReportable;

    if (contractId) {
        contract.id = contractId;
    }

    return contract;
  }
}
