import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MessageService} from "primeng/api";
import {UserService} from 'src/app/shared/service/userService';

@Component({
    selector: 'app-new-user',
    templateUrl: './new-user.component.html',
    styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {

    userForm: FormGroup;
    contractDataType: any = [];
    contractIsCreated: boolean = false;

    formTitle: string = 'Neuer Benutzer';
    // btnLabel: string = 'Speichern';

    id: any;
    userData: any;
    isAdmin: boolean = false;

    constructor(private userService: UserService, private messageService: MessageService, private router: Router, private route: ActivatedRoute) {
        this.userForm = new FormGroup({
            firstName: new FormControl("", Validators.required),
            lastName: new FormControl("", Validators.required),
            email: new FormControl("", Validators.required),
            password: new FormControl("", Validators.required),
            isAdmin: new FormControl(false, Validators.required),
        });
    }

    ngOnInit(): void {
        this.route.params.subscribe((params: Params) => {
            this.id = params['id'];

        });

        if (this.id) {
            this.formTitle = 'Benutzer aktualisieren';

            this.userForm.addControl('id', new FormControl(this.id, Validators.required));
            this.userForm.removeControl('password');
            // this.btnLabel = 'aktualisieren';
            this.getUser();
        }
    }

    getAdminValue(ev:any) {
        const admin = ev;

        if (admin) {
            this.isAdmin = true;
            this.userForm.get('isAdmin')?.setValue(this.isAdmin);
        }
        else {
            this.isAdmin = false;
            this.userForm.get('isAdmin')?.setValue(this.isAdmin);
        }
    }

    getUser() {
        this.userService.getUser(this.id)
            .then(res => {

                this.userData = res.data;
                this.userForm.patchValue(this.userData);
            })
            .catch(error => {
                console.error("Error fetching user data:", error);
            });
    }


    async save() {
        try {
            await this.userService.createNewUser(this.userForm.value);
            this.messageService.add({
                severity: 'success',
                summary: 'Erfolg',
                detail: 'Benutzer erfolgreich erstellt.'
            });
            this.contractIsCreated = true;
            this.afterSuccessfullyAdd();
        } catch (error) {
            this.contractIsCreated = false;
            this.messageService.add({
                severity: 'error',
                summary: 'Fehler',
                detail: 'Benutzer konnte nicht erstellt werden.',
            });
        }
    }

    async update() {
        try {
            await this.userService.put(this.id, this.userForm.value);
            this.messageService.add({
                severity: 'success',
                summary: 'Erfolg',
                detail: 'Benutzer wurde erfolgreich erstellt.'
            });
            this.contractIsCreated = true;
            this.afterSuccessfullyAdd();
        } catch (error) {
            this.contractIsCreated = false;
            this.messageService.add({
                severity: 'error',
                summary: 'Fehler',
                detail: 'Benutzer konnte nicht erstellt werden.',
            });
        }
    }

    get f() {
        return this.userForm.controls;
    }

    afterSuccessfullyAdd() {
        this.router.navigateByUrl("user-management");
    }
}
