<app-sidebar>
    <p-card>
        <div class="grid">
            <div class="xl:col-9 lg:col-9 md:col-12 sm:col-12">
                <h2 class="mt-0">Benutzerverwaltung</h2>
            </div>
            <div class="xl:col-3 lg:col-3 md:col-12 sm:col-12 text-end">
                <button
                        (click)="navigateToCreateUser()"
                        pButton
                        type="button"
                        label="Benutzer erstellen"
                        icon="pi pi-plus"
                        class="table-header-button p-button-sm"
                ></button>
            </div>
        </div>
        <p-toast></p-toast>
        <p-table
                 [value]="users?.data"
                 class="mt-5"
                 styleClass="p-datatable-striped"
                 responsiveLayout="scroll"
                 sortField="createdAt" [sortOrder]="-1"
                 [globalFilterFields]="['createdAt', 'buyerfirstname', 'vehicle', 'vehicle']">
            <!-- <ng-template pTemplate="caption">
                <div class="flex">
            <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="fulltextFilter($event.target)" placeholder="Suchen" />
            </span>
                </div>
            </ng-template> -->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="createdAt">Vorname
                        <p-sortIcon field="Erstellungsdatum"></p-sortIcon>
                    </th>
                    <th pSortableColumn="buyerfirstname">Nachname
                        <p-sortIcon field="Name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="vehicle">E-Mail
                        <p-sortIcon field="Fahrzeug"></p-sortIcon>
                    </th>
                    <th pSortableColumn="vehicle">Erstellt am
                        <p-sortIcon field="Fahrzeug"></p-sortIcon>
                    </th>
                   
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-userData>
                <tr>
                    <td>{{ userData.firstName }}</td>
                    <td>{{ userData.lastName }}</td>
                    <td>{{ userData.email }}</td>
                    <td>{{ userData.createdAt | date : "dd.MM.yyyy HH:mm" }}</td>
                    <td>
                        <button
                                (click)="onEdit(userData.id)"
                                pButton
                                icon="pi pi-user-edit"
                                class="custom-table-button"
                                pTooltip="Benutzer bearbeiten"
                        ></button>
                        <button
                               (click)="onDelete(userData.id)"
                                pButton
                                icon="pi pi-trash"
                                class="custom-table-button ml-3"
                                pTooltip="Benutzer löschen"
                               
                        ></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>
</app-sidebar>
