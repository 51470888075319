<app-sidebar>
    <p-card>
        <div class="grid">
            <div class="xl:col-9 lg:col-9 md:col-12 sm:col-12">
                <h2 class="mt-0">Auftragsbestätigungen</h2>
            </div>
            <div class="xl:col-3 lg:col-3 md:col-12 sm:col-12">
                <button
                        (click)="navigateToCreateOrderConfirmation()"
                        pButton
                        type="button"
                        label="AB anlegen"
                        icon="pi pi-plus"
                        class="table-header-button p-button-sm"
                ></button>
            </div>
        </div>
        <p-toast></p-toast>
        <p-table
                 [value]="orderConfirmations?.data"
                 class="mt-5"
                 styleClass="p-datatable-striped"
                 responsiveLayout="scroll"
                 sortField="createdAt" [sortOrder]="-1"
                 [globalFilterFields]="['createdAt', 'buyerfirstname', 'vehicle', 'vehicle']">
            <ng-template pTemplate="caption">
                <div class="flex">
            <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="fulltextFilter($event.target)" placeholder="Suchen" />
            </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="createdAt">Erstellungsdatum
                        <p-sortIcon field="Erstellungsdatum"></p-sortIcon>
                    </th>
                    <th pSortableColumn="buyerfirstname">Kunde
                        <p-sortIcon field="Name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="vehicle">Fahrzeug
                        <p-sortIcon field="Fahrzeug"></p-sortIcon>
                    </th>
                    <th pSortableColumn="vehicle">Verkäufer
                        <p-sortIcon field="Fahrzeug"></p-sortIcon>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-orderConfirmationData>
                <tr>
                    <td>{{ orderConfirmationData.createdAt | date : "dd.MM.yyyy HH:mm" }}</td>
                    <td>{{ orderConfirmationData.firstName + ' ' + orderConfirmationData.lastName }}</td>
                    <td>{{ orderConfirmationData.vehicle }}</td>
                    <td>{{ getFullSellersName(orderConfirmationData) }}</td>
                    <td>
                        <button
                                pButton
                                icon="pi pi-download"
                                class="custom-table-button"
                                pTooltip="Auftragsbestätigung herunterladen"
                                (click)="downloadOrderConfirmation(orderConfirmationData.id)"
                        ></button>
                        <button
                                [disabled]="this.disable"
                                pButton
                                icon="pi pi-envelope"
                                class="custom-table-button"
                                pTooltip="Auftragsbestätigung an Kunden senden"
                                (click)="sendToCustomer(orderConfirmationData.id)"
                        ></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>
</app-sidebar>
