import {environment} from '../../../environments/environment';
import {CurrentUser} from './currentUser';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import {Role} from './role';

export const GlobalConstants = {
    host: environment.host,
    JWT: 'PUW_CM_JWT',
    REFRESH: 'REFRESH_TOKEN',
    sidebarActive: true,
    renderEndpoint: 'https://render.carbone.io/render/',
};

export function api(path: string): string {
    return GlobalConstants.host + path;
}

export function getUserFromToken(): CurrentUser {
    try {
        let token = localStorage.getItem(GlobalConstants.JWT);
        if (token) {
            const decode = jwtDecode<JwtPayload>(token);
            let currentUser = new CurrentUser();
            // @ts-ignore
            currentUser.userId = decode.sub;
            // @ts-ignore
            currentUser.email = decode.username;
            // @ts-ignore
            currentUser.firstName = decode.firstname;
            // @ts-ignore
            currentUser.lastName = decode.lastname;
            return currentUser;
        }
    } catch (Error) {
        console.warn(Error);
    }
    return new CurrentUser();
}

export function getRoleFromString(r: string): Role {
    if (r === Role.Administrator) {
        return Role.Administrator;
    } else if (r === Role.User) {
        return Role.User;
    } else {
        return Role.None;
    }
}

