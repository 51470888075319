<app-sidebar>
    <p-toast></p-toast>
    <form [formGroup]="orderconfirmationForm">
        <div class="custom-contract-card">
            <p-card>
                <h2 class="mt-0">Neue Auftragsbestätigung</h2>
                <div class="grid mt-5">
                    <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="salutation" pInputText type="text"/>
                                <label>Anrede</label>
                            </span>
                    </div>
                    <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="firstName" pInputText type="text"/>
                                <label>Vorname(n)</label>
                            </span>
                    </div>
                    <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="lastName" pInputText type="text"/>
                                <label>Nachname</label>
                            </span>
                    </div>
                    <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="street" pInputText type="text"/>
                                <label>Strasse</label>
                            </span>
                    </div>
                    <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="zip" pInputText type="text"/>
                                <label>Postleitzahl</label>
                            </span>
                    </div>
                    <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="city" pInputText type="text"/>
                                <label>Ort</label>
                            </span>
                    </div>
                    <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="phone" pInputText type="text"/>
                                <label>Telefon</label>
                            </span>
                    </div>
                    <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="mobile" pInputText type="text"/>
                                <label>Mobil</label>
                            </span>
                    </div>
                    <div class="col-4 mt-4 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="email" pInputText type="text"/>
                                <label>E-Mail</label>
                            </span>
                    </div>
                </div>
                <div class="grid mt-8">
                    <div class="col-8 p-fluid">
                            <span class="p-float-label">
                                <input formControlName="vehicle" pInputText type="text"/>
                                <label>Fahrzeugbezeichnung</label>
                            </span>
                    </div>
                    <div class="col-4 p-fluid">
                            <span class="p-float-label">
                                <p-inputMask mask="99.99.9999" slotChar="mm.dd.yyyy" formControlName="orderConfirmationDate" placeholder="tt.mm.jjjj"></p-inputMask>
                                <label>Datum Auftragsbestätigung</label>
                            </span>
                    </div>
                </div>
                <p class="mt-8"></p>
                <div style="display: flex;">
                <form [formGroup]="taxselect">
                    <p-selectButton [options]="taxOptions" (onChange)="changeTaxState($event)" formControlName="value" optionLabel="label" optionValue="value"></p-selectButton>
                </form>
                <form [formGroup]="netgrossselect" *ngIf="vatReportable" class="ml-5">
                    <p-selectButton [options]="stateOptions" (onChange)="changeNetGross($event)" formControlName="value" optionLabel="label" optionValue="value"></p-selectButton>
                </form>
            </div>
                <div class="grid mt-5">
                    <div class="col-4 p-fluid" *ngIf="vatReportable">
                    <span class="p-float-label">
                        <p-inputNumber formControlName="priceNet" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" locale="de-DE" (onBlur)="priceNetChanged()"></p-inputNumber>
                        <label>Preis (netto)</label>
                    </span>
                    </div>
                    <div class="col-4 p-fluid" *ngIf="vatReportable">
                    <span class="p-float-label">
                        <p-inputNumber formControlName="tax" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" locale="de-DE"></p-inputNumber>
                        <label>19% MwSt.</label>
                    </span>
                    </div>
                    <div class="col-4 p-fluid">
                    <span class="p-float-label">
                        <p-inputNumber formControlName="priceGross" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" locale="de-DE" (onBlur)="priceGrossChanged()"></p-inputNumber>
                        <label>Preis</label>
                    </span>
                    </div>
                </div>
                <p class="mt-4"></p>
                <p-toast></p-toast>
                <ng-template pTemplate="footer">
                    <div *ngIf="!contractIsCreated" class="grid">
                        <div class="col-10"></div>
                        <div class="col-2">
                            <button (click)="save()" [disabled]="orderconfirmationForm.invalid" class="custom-save-button"
                                    icon="pi pi-save" label="Speichern" pButton type="submit"></button>
                        </div>
                    </div>
                </ng-template>
            </p-card>
        </div>
    </form>
</app-sidebar>
