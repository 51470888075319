import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {MessageService} from "primeng/api";
import {handleError} from "../../shared/tools/error-helper";
import {HttpClient} from "@angular/common/http";
import {ContractService} from "../../shared/service/contractService";
import {Data} from "../../shared/models/data";
import {FormType} from "../../shared/models/formType";
import { AuthService } from "src/app/auth/auth.service";

@Component({
    selector: "app-contract",
    templateUrl: "./contract.component.html",
    styleUrls: ["./contract.component.scss"],
})
export class ContractComponent implements OnInit {
    public submissionDetailsVisible: boolean = false;
    public contracts: any;
    public data: Data[] = [];

    constructor(private router: Router, 
                private contractService: ContractService, 
                private messageService: MessageService,
              ) {

                
    }

    async ngOnInit() {
        await this.loadContracts();
    }

    async loadContracts(): Promise<void> {
        try {
            this.contracts = await this.contractService.listAllContracts();
            this.getContractData(this.contracts.data);
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Fehler',
                detail: 'Die Liste konnte nicht geladen werden.',
            });
            handleError(error, this.router);
        }
    }

    getContractData(contracts: any) {
        this.data = [...contracts];
        return this.data;
    }

    showSubmissionDetails(id: string, formtype: string, routeType: string) {
        if (id && formtype) {
            if (formtype === FormType.NewCar) {
                this.router.navigateByUrl(`dashboard/new-car/${routeType}/${id}`);
            } else if (formtype === FormType.UsedCar) {
                this.router.navigateByUrl(`dashboard/used-car/${routeType}/${id}`);
            } else if (formtype === FormType.NewCamper) {
                this.router.navigateByUrl(`dashboard/new-camper/${routeType}/${id}`);
            } else if (formtype === FormType.UsedCamper) {
                this.router.navigateByUrl(`dashboard/used-camper/${routeType}/${id}`);
            } else if (formtype === FormType.Oldtimer) {
                this.router.navigateByUrl(`dashboard/oldtimer/${routeType}/${id}`);
            } else if (formtype === FormType.DayAdmission) {
                this.router.navigateByUrl(`dashboard/day-admission/${routeType}/${id}`);
            }
        }
    }

    navigateToHome() {
        this.router.navigateByUrl("dashboard/home");
    }

    getEnumName(type: string) {
        if (type) {
            if (type === 'NewCar' || type === 'Neuwagen') {
                return 'Neuwagen';
            } else if (type === 'UsedCar' || type === 'Gebrauchtwagen') {
                return 'Gebrauchtwagen';
            } else if (type === 'NewCamper' || type === 'Reisemobil neu') {
                return 'Reisemobil neu';
            } else if (type === 'UsedCamper' || type === 'Reisemobil gebraucht') {
                return 'Reisemobil gebraucht';
            } else if (type === 'Oldtimer') {
                return 'Oldtimer';
            } else if (type === 'DayAdmission' || type === 'Tageszulassung/Vorführwagen') {
                return 'Tageszulassung/Vorführwagen';
            }
        }
        return '';
    }
}
