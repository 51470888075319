<p-table [tableStyle]="{'min-width': '50rem'}" [value]="agreements" dataKey="id"
         editMode="row">
    <ng-template pTemplate="header">
        <tr>
            <th>
                <p>Vereinbarungen</p>
                <span *ngIf="!contractIsCreated">
                                            <div class="grid align-items-center">
                                                <button (click)="openSelectionPopup()" class="mr-2" icon="pi pi-plus"
                                                        label="Vereinbarung auswählen" pButton></button>
                                                <button (click)="showAgreementPopup()" icon="pi pi-plus"
                                                        label="Vereinbarung erstellen" pButton></button>
                                            </div>
                                        </span>
                <span *ngIf="contractIsCreated">
                                            <h4>Vereinbarungen</h4>
                                        </span>
            </th>
            <th></th>
            <th></th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template let-preContractInfo pTemplate="body">
        <tr>
            <td [class]="preContractInfo.placeholder ? 'placeholderrow' : ''"
                [innerHTML]="preContractInfo.content"></td>
            <td [innerHTML]="preContractInfo.description"></td>
            <td>
                                        <span *ngIf="!contractIsCreated">
                                            <button
                                                    icon="pi pi-pencil" pButton></button>
                                        </span>
            </td>
            <td>
                                        <span *ngIf="!contractIsCreated">
                                            <button
                                                    icon="pi pi-trash" pButton></button>
                                        </span>
            </td>
        </tr>
    </ng-template>
</p-table>

<p-dialog [(visible)]="isAgreementSelectionVisible" [closable]="false" [draggable]="false" [maximizable]="true"
          [modal]="true" [resizable]="false" appendTo="body" header="Vereinbarungen">
    <p-card>
        <p-table #newcartable [globalFilterFields]="['id', 'content', 'description']" [loading]='loading'
                 [rows]="20" [scrollable]="true" selectionMode="multiple" [(selection)]="selectedAgreements"
                 [tableStyle]="{'min-width': '50rem'}" [value]="preContractInformations" dataKey="id"
                 scrollHeight="flex" styleClass=' p-datatable-striped'>

            <ng-template pTemplate="header">
                <tr>
                    <th width="40%">Inhalt</th>
                    <th width="40%">Beschreibung</th>
                    <th width="5%">Platzhalter</th>
                    <th width="5%">Standard</th>
                    <th width="10%">Hinzufügen</th>
                </tr>
            </ng-template>
            <ng-template let-data pTemplate="body">
                <tr>
                    <td [innerHTML]="data.content">
                        <!-- content -->
                    </td>
                    <td [innerHTML]="data.description">
                        <!-- description -->
                    </td>
                    <td>
                                <span *ngIf="checkState(data.placeholder)">
                                    <i class="pi pi-check-circle" style="color: green"></i>
                                </span>
                    </td>
                    <td>
                                <span *ngIf="checkState(data.default)">
                                    <i class="pi pi-check-circle" style="color: green"></i>
                                </span>
                    </td>
                    <td>
                                <span *ngIf="!checkState(data.default)">
                                    <p-tableCheckbox [value]="data"></p-tableCheckbox>
                                </span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>
    <ng-template pTemplate="footer">
        <div class="grid">
            <div class="col-8"></div>
            <div class="col-4">
                <button (click)="addPreContractInformationsToTable()" class="custom-save-button"
                        icon="pi pi-paperclip" label="Hinzufügen" pButton type="button"></button>
                <button (click)="closeAgreementSelectionPopup()" class="custom-save-button p-button-danger"
                        icon="pi pi-times" label="Abbrechen" pButton type="button"></button>
            </div>
        </div>
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="isAgreementVisible" [closable]="false" [draggable]="false" [maximizable]="true"
          [modal]="true" [resizable]="false" appendTo="body" header="Eigene Vereinbarung hinzufügen">
    <div class="mt-4"></div>
    <p-editor [style]="{ height: '200px' }" [ngModel]="text" (ngModelChange)="onTextChange($event)"
              [ngModelOptions]="{standalone: true}"></p-editor>

    <ng-template pTemplate="footer">
        <div class="grid">
            <div class="col-8"></div>
            <div class="col-4">
                <button (click)="createAgreementToTable()" class="custom-save-button" icon="pi pi-paperclip"
                        label="Hinzufügen" pButton type="button"></button>
                <button (click)="closeAgreementPopup()" class="custom-save-button p-button-danger"
                        icon="pi pi-times" label="Abbrechen" pButton type="button"></button>
            </div>
        </div>
    </ng-template>
</p-dialog>
