import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {MessageService} from "primeng/api";
import {handleError} from "../../shared/tools/error-helper";
import {OrderConfirmationService} from "../../shared/service/orderConfirmationService";
import {Orderconfirmation} from "../../shared/models/orderconfirmation";

@Component({
  selector: 'app-orderconfirmation',
  templateUrl: './orderconfirmation.component.html',
  styleUrls: ['./orderconfirmation.component.scss']
})
export class OrderconfirmationComponent implements OnInit {
  public orderConfirmations: any;
  public data: Orderconfirmation[] = [];
  public disable = false;

  constructor(private router: Router, private orderconfirmationService: OrderConfirmationService, private messageService: MessageService) {
  }

  async ngOnInit() {
    await this.loadOrderConfirmations();
  }

  async loadOrderConfirmations(filter?: string): Promise<void> {
    try {
      if (filter) {
        this.orderConfirmations = await this.orderconfirmationService.listAllOrderConfirmationsWithFilter(filter);
      } else {
        this.orderConfirmations = await this.orderconfirmationService.listAllOrderConfirmations();
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: 'Die Liste konnte nicht geladen werden.',
      });
      handleError(error, this.router);
    }
  }

  navigateToCreateOrderConfirmation() {
    this.router.navigateByUrl("dashboard/new-orderconfirmation");
  }

  getFullSellersName(orderConfirmation: Orderconfirmation) {
    return orderConfirmation.sellerFirstName + " " + orderConfirmation.sellerLastName;
  }

  async downloadOrderConfirmation(id: string) {
    if (id) {
      let orderConfirmation: any = await this.orderconfirmationService.getOrderConfirmationById(id);
      await this.orderconfirmationService.downloadAndSendOrderConfirmation(orderConfirmation.data, true, false, false);
      this.disableButtonsAndEnableItAgain();
    }
  }

  async sendToCustomer(id: string) {
    try {
      if (id) {
        this.disableButtonsAndEnableItAgain();
        let orderConfirmation: any = await this.orderconfirmationService.getOrderConfirmationById(id);
        console.warn(orderConfirmation.data);
        await this.orderconfirmationService.downloadAndSendOrderConfirmation(orderConfirmation.data, false, true, false);
      }
    } catch (error) {
      handleError(error, this.router);
    }
  }

  disableButtonsAndEnableItAgain() {
    this.disable = true;
    setTimeout(() => {
      this.disable = false;
    }, 10000);
  }

  fulltextFilter(event: any) {
    this.loadOrderConfirmations(event.value);
  }
}
