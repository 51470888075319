<!--<div [ngClass]="{ hideSideBar: menuActive }" class="layout-sidebar hideSideBar">-->
<!--    <a [routerLink]="['/dashboard']" class="sidebar-header">-->
<!--        <img alt="logo" src="assets/images/puw-logo.png" />-->
<!--    </a>-->
<!--    <div class="layout-menu">-->
<!--        <div class="menu-items">-->
<!--            <p-panelMenu class="pt-xl-3" [model]="menuItems" (click)="activateMenu($event)"></p-panelMenu>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div class="min-h-screen flex relative lg:static surface-ground">
    <div *ngIf="menu"
        class="bg-bluegray-800 h-screen lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 select-none"
        style="width:280px">
        <div class="flex flex-column h-full">
            <div class="flex align-items-center justify-content-center p-3 bg-bluegray-900 flex-shrink-0">
                <img alt="PuW Logo" height="50" src="assets/images/puw-logo.png">
            </div>
            <div class="overflow-y-auto mt-3 list-none p-3 m-0">
                <ul class="list-none p-3 m-0">
                    <li>
                        <a *ngFor="let item of menuItems" class="flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                                transition-duration-150 transition-colors" pRipple [routerLink]="item.routerLink">
                            <i class="text-white pi {{ item.icon }} mr-2"></i>
                            <span class="font-bold"><a class="text-white">{{item.label}}</a></span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="mt-auto">
                <hr class="mb-3 mx-3 border-top-1 border-none border-bluegray-600" />
                <ul
                    class="list-none p-2 m-0 hidden origin-bottom animation-duration-150 overflow-hidden animation-ease-in-out">
                    <li *ngFor="let item of userMenu">
                        <a class="flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                            transition-duration-150 transition-colors" pRipple (click)="item?.command?.({})">
                            <i class="text-white pi {{item.icon}}"></i>
                            <span class="font-medium">{{item.label}}</span>
                        </a>
                    </li>
                </ul>
                <a class="m-3 px-3 py-2 flex align-items-center hover:bg-bluegray-900 border-round cursor-pointer text-bluegray-100 hover:text-bluegray-50transition-duration-150 transition-colors"
                    enterActiveClass="slidedown" enterClass="hidden" leaveActiveClass="slideup" leaveToClass="hidden"
                    pRipple pStyleClass="@prev">
                    <span class="font-medium menustyle">{{currentUser.email}}</span>
                    <i class="pi pi-chevron-up ml-auto menustyle"></i>
                </a>
            </div>
        </div>
    </div>
    <div class="min-h-screen flex flex-column relative flex-auto">
        <div class="flex justify-content-between align-items-center px-5 surface-section shadow-2 relative lg:static border-bottom-1 surface-border"
            style="height:60px">
            <div class="flex">

                <div id="menu" (click)="onMenuIcon()" [ngClass]="!menu ? 'opened' : 'closed'">
                    <a id="open-close">
                        <i class="pi text-4xl" [ngClass]="{'pi-bars': !menu}"></i>
                    </a>
                </div>

                <!--                <span class="p-input-icon-left">-->
                <!--                        <i class="pi pi-search"></i>-->
                <!--                        <input class="border-none w-10rem sm:w-20rem" pInputText placeholder="Suche" type="text">-->
                <!--                    </span>-->
            </div>
            <div id="menu" (click)="onMenuIcon()" [ngClass]="!menu ? 'opened' : 'closed'">
                <a id="open-close">
                    <i class="pi text-4xl" [ngClass]="{'pi-ellipsis-v': menu}"></i>
                </a>
            </div>


            <!--            <ul class="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">-->
            <!--                <li>-->
            <!--                    <a class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer-->
            <!--                            transition-duration-150 transition-colors" pRipple>-->
            <!--                        <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0" pBadge severity="danger"></i>-->
            <!--                        <span class="block lg:hidden font-medium">Nachrichten</span>-->
            <!--                    </a>-->
            <!--                </li>-->
            <!--            </ul>-->
        </div>
        <div class="p-4 flex flex-column flex-auto custom-scroll">
            <div class="border-2 border-dashed surface-border border-round surface-section flex-auto">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>