<app-sidebar>
    <p-card>
        <div class="grid">
            <div class="xl:col-9 lg:col-9 md:col-12 sm:col-12">
                <h2 class="mt-0">Verbindliche Bestellungen</h2>
            </div>
            <div class="xl:col-3 lg:col-3 md:col-12 sm:col-12">
                <button
                        (click)="navigateToHome()"
                        pButton
                        type="button"
                        label="Vertrag anlegen"
                        icon="pi pi-plus"
                        class="table-header-button p-button-sm"
                ></button>
            </div>
        </div>
        <p-table
                [value]="contracts"
                class="mt-5"
                styleClass="p-datatable-striped"
                responsiveLayout="scroll"
                sortField="createdAt" [sortOrder]="-1">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="createdAt">Erstellungsdatum
                        <p-sortIcon field="Erstellungsdatum"></p-sortIcon>
                    </th>
                    <th pSortableColumn="buyerfirstname">Kunde
                        <p-sortIcon field="Name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="vehicle">Fahrzeug
                        <p-sortIcon field="Fahrzeug"></p-sortIcon>
                    </th>
                    <th pSortableColumn="formtype">Typ
                        <p-sortIcon field="Typ"></p-sortIcon>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-submissionData>
                <tr>
                    <td>{{ submissionData.createdAt | date : "dd.MM.yyyy HH:mm" }}</td>
                    <td>{{ submissionData.buyerfirstname + ' ' + submissionData.buyerlastname }}</td>
                    <td>{{ submissionData.vehicle }}</td>
                    <td>{{ getEnumName(submissionData.formtype) }}</td>
                    <td>
                        <button
                                pButton
                                icon="pi pi-search"
                                (click)="showSubmissionDetails(submissionData.id, submissionData.formtype, 'view')"
                                class="custom-table-button"
                        ></button>
                        <button
                                pButton
                                icon="p-button-icon pi pi-pencil"
                                (click)="showSubmissionDetails(submissionData.id, submissionData.formtype, 'edit')"
                                class="custom-table-button"
                        ></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>
</app-sidebar>
