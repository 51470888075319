export class Orderconfirmation {
    userId: string = '';
    priceNetText: boolean = false;
    salutation: string = '';
    firstName: string = '';
    lastName: string = '';
    street: string = '';
    zip: string = '';
    city: string = '';
    country: string = '';
    phone: string = '';
    mobile: string = '';
    email: string = '';
    vehicle: string = '';
    priceNet: number = 0;
    priceGross: number = 0;
    tax: number = 0;
    vatReportable: boolean = false;
    orderConfirmationDate: string = '';
    sellerFirstName: string = '';
    sellerLastName: string = '';
    sellerEmailAddress: string = '';
}
