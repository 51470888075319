<app-sidebar>
    <p-toast></p-toast>
    <form [formGroup]="contractForm">
        <div class="custom-contract-card">
            <p-card>
                <h2 class="mt-0">Tageszulassung/Vorführwagen</h2>
                <div class="mb-4">
                    <p-button (click)="toggle(0)" [icon]="activeState[0] ? 'pi pi-minus' : 'pi pi-plus'"
                        label="Vorvertragliche Informationen" styleClass="p-button-text"></p-button>
                    <p-button (click)="toggle(1)" [icon]="activeState[0] ? 'pi pi-minus' : 'pi pi-plus'"
                        label="Verbindliche Bestellung" styleClass="p-button-text ml-2"></p-button>
                </div>
                <p-accordion [multiple]="true">
                    <p-accordionTab [(selected)]="activeState[0]" header="Vorvertragliche Informationen">
                        <h3>Informationen zum Käufer</h3>
                        <div class="grid mt-5">
                            <div class="col-4 p-fluid">
                                <span class="p-float-label">
                                    <p-dropdown formControlName="contracttype" [options]="contractDataType"
                                        [autoDisplayFirst]="false" optionLabel="name"></p-dropdown>
                                    <label>Privat/Gewerblich</label>
                                </span>
                                <ng-container *ngIf="contractForm.controls['contracttype'].touched">
                                    <span class="text-danger" *ngIf="contractForm.get('contracttype')?.getError('required')">
                                        Das Feld Privat/Gewerblich ist ein Pflichtfeld.
                                    </span>
                                </ng-container>
                            </div>
                            <div class="col-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="vehicle" pInputText type="text" />
                                    <label>Fahrzeug</label>
                                </span>
                                <ng-container *ngIf="contractForm.controls['vehicle'].touched">
                                    <span class="text-danger" *ngIf="contractForm.get('vehicle')?.getError('required')">
                                        Das Feld Fahrzeug ist ein Pflichtfeld.
                                    </span>
                                </ng-container>
                            </div>
                        </div>
                        <div class="grid mt-5">
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="buyersalutation" pInputText type="text" />
                                    <label>Anrede</label>
                                </span>
                                <ng-container *ngIf="contractForm.controls['buyersalutation'].touched">
                                    <span class="text-danger" *ngIf="contractForm.get('buyersalutation')?.getError('required')">
                                        Das Feld Anrede ist ein Pflichtfeld.
                                    </span>
                                </ng-container>
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="buyerfirstname" pInputText type="text" />
                                    <label>Vorname(n)</label>
                                </span>
                                <ng-container *ngIf="contractForm.controls['buyerfirstname'].touched">
                                    <span class="text-danger" *ngIf="contractForm.get('buyerfirstname')?.getError('required')">
                                        Das Feld Vorname(n) ist ein Pflichtfeld.
                                    </span>
                                </ng-container>
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="buyerlastname" pInputText type="text" />
                                    <label>Nachname</label>
                                </span>
                                <ng-container *ngIf="contractForm.controls['buyerlastname'].touched">
                                    <span class="text-danger" *ngIf="contractForm.get('buyerlastname')?.getError('required')">
                                        Das Feld Nachname ist ein Pflichtfeld.
                                    </span>
                                </ng-container>
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="buyerstreet" pInputText type="text" />
                                    <label>Strasse</label>
                                </span>
                                <ng-container *ngIf="contractForm.controls['buyerstreet'].touched">
                                    <span class="text-danger" *ngIf="contractForm.get('buyerstreet')?.getError('required')">
                                        Das Feld Strasse ist ein Pflichtfeld.
                                    </span>
                                </ng-container>
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="buyerzip" pInputText type="text" />
                                    <label>Postleitzahl</label>
                                </span>
                                <ng-container *ngIf="contractForm.controls['buyerzip'].touched">
                                    <span class="text-danger" *ngIf="contractForm.get('buyerzip')?.getError('required')">
                                        Das Feld Postleitzahl ist ein Pflichtfeld.
                                    </span>
                                </ng-container>
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="buyercity" pInputText type="text" />
                                    <label>Ort</label>
                                </span>
                                <ng-container *ngIf="contractForm.controls['buyercity'].touched">
                                    <span class="text-danger" *ngIf="contractForm.get('buyercity')?.getError('required')">
                                        Das Feld Ort ist ein Pflichtfeld.
                                    </span>
                                </ng-container>
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="buyerphone" pInputText type="text" />
                                    <label>Telefon</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="buyermobile" pInputText type="text" />
                                    <label>Mobil</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="buyeremail" pInputText type="text" />
                                    <label>E-Mail</label>
                                </span>
                            </div>
                        </div>
                        <p class="mt-4"></p>
                        <p-toast></p-toast>
                        <!-- mazhar code -->
                        <p-table [tableStyle]="{'min-width': '50rem'}" [value]="newContractInformations" dataKey="id"
                            editMode="row">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>
                                        <p>Vereinbarungen</p>
                                        <span *ngIf="!contractIsCreated">
                                            <div class="grid align-items-center">
                                                <button (click)="openSelectionPopup()" class="mr-2" icon="pi pi-plus"
                                                    label="Vereinbarung auswählen" pButton></button>
                                                <button (click)="showAgreementPopup()" icon="pi pi-plus"
                                                    label="Vereinbarung erstellen" pButton></button>
                                            </div>
                                        </span>
                                        <span *ngIf="contractIsCreated">
                                            <h4>Vereinbarungen</h4>
                                        </span>
                                    </th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </ng-template>
                            <ng-template let-preContractInfo pTemplate="body">
                                <tr>
                                    <td [class]="preContractInfo.placeholder ? 'placeholderrow' : ''"
                                        [innerHTML]="preContractInfo.content"></td>
                                    <td [innerHTML]="preContractInfo.description"></td>
                                    <td>
                                        <span *ngIf="!contractIsCreated">
                                            <button (click)="editPrecontractInformation(preContractInfo)"
                                                icon="pi pi-pencil" pButton></button>
                                        </span>
                                    </td>
                                    <td>
                                        <span *ngIf="!contractIsCreated">
                                            <button (click)="removePrecontractInformation(preContractInfo)"
                                                icon="pi pi-trash" pButton></button>
                                        </span>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <!-- mazhar code -->
                    </p-accordionTab>
                    <p-accordionTab [(selected)]="activeState[0]" header="Verbindliche Bestellung">
                        <h3>Informationen zur Bestellung</h3>
                        <div class="grid mt-5">
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="brand" pInputText type="text" />
                                    <label>Marke</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="equipment" pInputText type="text" />
                                    <label>Type in Serienmäßiger Ausführung</label>
                                </span>
                            </div>
                            <!--            <div class="col-4 mt-4 p-fluid">-->
                            <!--              <span class="p-float-label">-->
                            <!--                <input formControlName="model" pInputText type="text" />-->
                            <!--                <label>Modell</label>-->
                            <!--              </span>-->
                            <!--            </div>-->
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="engine" pInputText type="text" />
                                    <label>Motor</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="color" pInputText type="text" />
                                    <label>Farbe</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="interior" pInputText type="text" />
                                    <label>Polster/Farbe</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="tires" pInputText type="text" />
                                    <label>Bereifung</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="vin" pInputText type="text" />
                                    <label>Fahrgestellnummer</label>
                                </span>
                                <ng-container *ngIf="contractForm.controls['vin'].touched">
                                    <span class="text-danger" *ngIf="contractForm.get('vin')?.getError('required')">
                                        Das Feld Fahrgestellnummer ist ein Pflichtfeld.
                                    </span>
                                </ng-container>
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="mileage" pInputText type="text" />
                                    <label>Kilometerstand</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="firstregistration" pInputText type="text" />
                                    <label>Erstzulassung</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="mainexaminationemissiontest" pInputText type="text" />
                                    <label>HU | AU</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="previousowner" pInputText type="text" />
                                    <label>Vorbesitzer</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <input formControlName="deliverydate" pInputText type="text">
                                    <label>Unverbindlicher Liefertermin/Lieferzeit</label>
                                    <!--TODO: tooltip -->
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <span class="p-float-label">
                                    <p-inputMask formControlName="contractdate" mask="99.99.9999"
                                        placeholder="dd.mm.yyyy"></p-inputMask>
                                    <label>Vertragsdatum</label>
                                </span>
                                <ng-container *ngIf="contractForm.controls['contractdate'].touched">
                                    <span class="text-danger" *ngIf="contractForm.get('contractdate')?.getError('required')">
                                        Das Feld Vertragsdatum ist ein Pflichtfeld.
                                    </span>
                                </ng-container>
                            </div>
                        </div>
                        <div class="grid mt-5">
                            <div class="col-12">
                                <h3>Berechnung</h3>
                            </div>
                            <div class="col-12 mt-4 d-flex" *ngIf="type !== 'view'">
                                <div class="p-field-radiobutton mb-3">
                                    <p-radioButton id="MwSt" name="MwSt" value="val1" (ngModelChange)="onChange($event)" [(ngModel)]="selectedValue" [ngModelOptions]="{standalone: true}" inputId="MwSt"></p-radioButton>
                                    <label for="MwSt" class="ml-2">MwSt. ausweisbar</label>
                                </div>
                                <div class="p-field-radiobutton ml-3">
                                    <p-radioButton id="Nicht" name="Nicht" value="val2" (ngModelChange)="onChange($event)" [(ngModel)]="selectedValue" [ngModelOptions]="{standalone: true}" inputId="Nicht"></p-radioButton>
                                    <label for="Nicht" class="ml-2">Nicht ausweisbar</label>
                                </div>
                            </div>

                            <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                                <span class="p-float-label p-input-icon-left">
                                    <i class="pi pi-euro"></i>
                                    <input (input)="vehiclePriceNetChanged()" formControlName="vehiclepricenet"
                                        pInputText type="number" />
                                    <label>Fahrzeugpreis netto</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                                <span class="p-float-label p-input-icon-left">
                                    <i class="pi pi-euro"></i>
                                    <input (input)="vehiclePriceGrossChanged()" formControlName="vehiclepricegross"
                                        pInputText type="number" />
                                    <label>Fahrzeugpreis brutto</label>
                                </span>
                            </div>

                            <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">

                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">

                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <!-- placeholder -->
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                                <span class="p-float-label p-input-icon-left">
                                    <i class="pi pi-euro"></i>
                                    <input (input)="additionalsNetChanged()" formControlName="additionalsnet" pInputText
                                        type="number" />
                                    <label>+ Sonderausstattung netto</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                                <span class="p-float-label p-input-icon-left">
                                    <i class="pi pi-euro"></i>
                                    <input (input)="additionalsGrossChanged()" formControlName="additionalsgross"
                                        pInputText type="number" />
                                    <label>+ Sonderausstattung brutto</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">

                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">

                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <!-- placeholder -->
                            </div>
                            <div class="col-9 p-fluid">
                                <div class="custom-border"></div>
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                                <span class="p-float-label p-input-icon-left">
                                    <i class="pi pi-euro"></i>
                                    <input formControlName="listpricenet" pInputText type="number" />
                                    <label>= Listenpreis netto</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                                <span class="p-float-label p-input-icon-left">
                                    <i class="pi pi-euro"></i>
                                    <input formControlName="listpricegross" pInputText type="number" />
                                    <label>= Listenpreis brutto</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                                <!-- placeholder -->
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                                <!-- placeholder -->
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <!-- placeholder -->
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                                <span class="p-float-label p-input-icon-left">
                                    <i class="pi pi-euro"></i>
                                    <input (input)="discountNetChanged()" formControlName="discountnet" pInputText
                                        type="number" />
                                    <label>- Nachlass netto</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                                <span class="p-float-label p-input-icon-left">
                                    <i class="pi pi-euro"></i>
                                    <input (input)="discountGrossChanged()" formControlName="discountgross" pInputText
                                        type="number" />
                                    <label>- Nachlass brutto</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                                <!-- placeholder -->
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                                <!-- placeholder -->
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <!-- placeholder -->
                            </div>
                            <div class="col-9 p-fluid">
                                <div class="custom-border"></div>
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                                <span class="p-float-label p-input-icon-left">
                                    <i class="pi pi-euro"></i>
                                    <input formControlName="housepricenet" pInputText type="number" />
                                    <label>= Hauspreis netto</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                                <span class="p-float-label p-input-icon-left">
                                    <i class="pi pi-euro"></i>
                                    <input formControlName="housepricegross" pInputText type="number" />
                                    <label>= Hauspreis brutto</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                                <!-- placeholder -->
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                                <!-- placeholder -->
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <!-- placeholder -->
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                                <span class="p-float-label p-input-icon-left">
                                    <i class="pi pi-euro"></i>
                                    <input (input)="accessoiresNetChanged()" formControlName="accessoiresnet" pInputText
                                        type="number" />
                                    <label>+ Zubehör netto</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                                <span class="p-float-label p-input-icon-left">
                                    <i class="pi pi-euro"></i>
                                    <input (input)="accessoiresGrossChanged()" formControlName="accessoiresgross"
                                        pInputText type="number" />
                                    <label>+ Zubehör brutto</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                                <!-- placeholder -->
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                                <!-- placeholder -->
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <!-- placeholder -->
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                                <span class="p-float-label p-input-icon-left">
                                    <i class="pi pi-euro"></i>
                                    <input (input)="transferNetChanged()" formControlName="transfernet" pInputText
                                        type="number" />
                                    <label>+ Fracht netto</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                                <span class="p-float-label p-input-icon-left">
                                    <i class="pi pi-euro"></i>
                                    <input (input)="transferGrossChanged()" formControlName="transfergross" pInputText
                                        type="number" />
                                    <label>+ Fracht brutto</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                                <!-- placeholder -->
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                                <!-- placeholder -->
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <!-- placeholder -->
                            </div>
                            <div class="col-9 p-fluid">
                                <div class="custom-border"></div>
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                                <span class="p-float-label p-input-icon-left">
                                    <i class="pi pi-euro"></i>
                                    <input formControlName="endpricenet" pInputText type="number" />
                                    <label>= Endpreis netto</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                                <span class="p-float-label p-input-icon-left">
                                    <i class="pi pi-euro"></i>
                                    <input formControlName="endpricegross" pInputText type="number" />
                                    <label>= Endpreis brutto</label>
                                </span>
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="vatReportable">
                                <!-- placeholder -->
                            </div>
                            <div class="col-4 mt-4 p-fluid" *ngIf="!vatReportable">
                                <!-- placeholder -->
                            </div>
                            <div class="col-4 mt-4 p-fluid">
                                <!-- placeholder -->
                            </div>
                        </div>
                        <div class="grid mt-8">
                            <div class="col-4 p-fluid">
                                <span class="p-float-label">
                                    <p-dropdown formControlName="paymenttype" [options]="contractDataPaymentType"
                                        [autoDisplayFirst]="false" optionLabel="name"></p-dropdown>
                                    <label>Zahlungsweise</label>
                                </span>
                            </div>

                            <!--            <div class="col-4 mt-4 p-fluid">-->
                            <!--              <span class="p-float-label">-->
                            <!--                <p-dropdown-->
                            <!--                  formControlName="paymentType"-->
                            <!--                  [autoDisplayFirst]="false"-->
                            <!--                  appendTo="body"-->
                            <!--                  optionLabel="type"-->
                            <!--                  [options]="paymentType"-->
                            <!--                ></p-dropdown>-->
                            <!--                <label>Zahlungsmethode</label>-->
                            <!--              </span>-->
                            <!--            </div>-->
                        </div>
                    </p-accordionTab>
                </p-accordion>
                <ng-template pTemplate="footer">
                    <div *ngIf="contractIsCreated" class="grid">
                        <div class="col-3">
                            <p-card header="Vorvertragliche Informationen" [style]='styleDownloadFileCard'>
                                <div class="grid">
                                    <div class="col-4">
                                    </div>
                                    <div class="col-4">
                                        <p-button icon="pi pi-download" label="Download"
                                        [disabled]="loadingPreContract" [loading]="loadingPreContract" (click)="onDownloadReport('preContract')"></p-button>
                                    </div>
                                    <div class="col-4">
                                    </div>
                                </div>
                            </p-card>
                        </div>
                        <div class="col-3">
                            <p-card header="Verbindliche Bestellung" [style]='styleDownloadFileCard'>
                                <div class="grid">
                                    <div class="col-4">
                                    </div>
                                    <div class="col-4">
                                        <p-button icon="pi pi-download" label="Download"
                                        [disabled]="loadingContract" [loading]="loadingContract" (click)="onDownloadReport('contract')"></p-button>
                                    </div>
                                    <div class="col-4">
                                    </div>
                                </div>
                            </p-card>
                        </div>
                        <div class="col-3">
                            <p-card header="Anhang zur Bestellung" [style]='styleDownloadFileCard'>
                                <div class="grid">
                                    <div class="col-4">
                                    </div>
                                    <div class="col-4">
                                        <p-button icon="pi pi-download" label="Download"
                                        [disabled]="loadingAttachment" [loading]="loadingAttachment" (click)="onDownloadReport('attachment')"></p-button>
                                    </div>
                                    <div class="col-4">
                                    </div>
                                </div>
                            </p-card>
                        </div>
                        <div class="col-3">
                            <p-card [style]='styleDownloadFileCard' header="Auftragsbestaetigung">
                                <div class="grid">
                                    <div class="col-4">
                                    </div>
                                    <div class="col-4">
                                        <p-button icon="pi pi-download" label="Download"
                                        [disabled]="loadingOrderConfirmation" [loading]="loadingOrderConfirmation" (click)="onDownloadReport('orderConfirmation')"></p-button>
                                    </div>
                                    <div class="col-4">
                                    </div>
                                </div>
                            </p-card>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="col-12 p-fluid" *ngIf="(type == 'view' || contractIsCreated)" style="text-align: right;">
                            <p-checkbox [binary]="true" label="In Connect übertragen"
                            [(ngModel)]="isConnect" [ngModelOptions]="{standalone: true}" (ngModelChange)="getConnectValue($event)"></p-checkbox>
                        </div>
                    </div>
                    <div *ngIf="!contractIsCreated" class="grid">
                        <div class="col-10"></div>
                        <div class="col-2" *ngIf="type !== 'edit'">
                            <button (click)="save()" class="custom-save-button" icon="pi pi-save" label="Speichern"
                                pButton type="submit" [disabled]="contractForm.invalid"></button>
                        </div>
                        <div class="col-2" *ngIf="type == 'edit'">
                            <button (click)="update()" class="custom-save-button" icon="pi pi-save" label="Aktualisieren"
                                pButton type="button" [disabled]="contractForm.invalid"></button>
                        </div>
                    </div>
                </ng-template>
            </p-card>
        </div>
        <!-- start preContractInfoPopup -->
        <p-dialog [(visible)]="isAgreementVisible" [closable]="false" [draggable]="false" [maximizable]="true"
            [modal]="true" [resizable]="false" appendTo="body" header="Eigene Vereinbarung hinzufügen">
            <div class="mt-4"></div>
            <p-editor [style]="{ height: '200px' }" [ngModel]="text" (ngModelChange)="onTextChange($event)"
                [ngModelOptions]="{standalone: true}"></p-editor>
            <ng-template pTemplate="footer">
                <div class="grid">
                    <div class="col-8"></div>
                    <div class="col-4">
                        <button (click)="createAgreementToTable()" class="custom-save-button" icon="pi pi-paperclip"
                            label="Hinzufügen" pButton type="button"></button>
                        <button (click)="closeAgreementPopup()" class="custom-save-button p-button-danger"
                            icon="pi pi-times" label="Abbrechen" pButton type="button"></button>
                    </div>
                </div>
            </ng-template>
        </p-dialog>
        <!--end start preContractInfoPopup -->
        <p-dialog [(visible)]="isAgreementSelectionVisible" [closable]="false" [draggable]="false" [maximizable]="true"
            [modal]="true" [resizable]="false" appendTo="body" header="Vereinbarungen">
            <p-card>
                <p-table #newcartable [globalFilterFields]="['id', 'content', 'description']" [loading]='loading'
                    [rows]="20" [scrollable]="true" selectionMode="multiple" [(selection)]="selectedAgreements"
                    [tableStyle]="{'min-width': '50rem'}" [value]="preContractInformations" dataKey="id"
                    scrollHeight="flex" styleClass=' p-datatable-striped'>

                    <ng-template pTemplate="header">
                        <tr>
                            <th width="40%">Inhalt</th>
                            <th width="40%">Beschreibung</th>
                            <th width="5%">Platzhalter</th>
                            <th width="5%">Standard</th>
                            <th width="10%">Hinzufügen</th>
                        </tr>
                    </ng-template>
                    <ng-template let-data pTemplate="body">
                        <tr>
                            <td [innerHTML]="data.content">
                                <!-- content -->
                            </td>
                            <td [innerHTML]="data.description">
                                <!-- description -->
                            </td>
                            <td>
                                <span *ngIf="checkState(data.placeholder)">
                                    <i class="pi pi-check-circle" style="color: green"></i>
                                </span>
                            </td>
                            <td>
                                <span *ngIf="checkState(data.default)">
                                    <i class="pi pi-check-circle" style="color: green"></i>
                                </span>
                            </td>
                            <td>
                                <span *ngIf="!checkState(data.default)">
                                    <p-tableCheckbox [value]="data"></p-tableCheckbox>
                                </span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-card>
            <ng-template pTemplate="footer">
                <div class="grid">
                    <div class="col-8"></div>
                    <div class="col-4">
                        <button (click)="addPreContractInformationsToTable()" class="custom-save-button"
                            icon="pi pi-paperclip" label="Hinzufügen" pButton type="button"></button>
                        <button (click)="closeAgreementSelectionPopup()" class="custom-save-button p-button-danger"
                            icon="pi pi-times" label="Abbrechen" pButton type="button"></button>
                    </div>
                </div>
            </ng-template>
        </p-dialog>
        <!-- TODO: change to new edit popup for agreements -->
        <p-dialog [(visible)]="showPciEditField" [closable]="false" [draggable]="false" [maximizable]="true"
            [modal]="true" [resizable]="false" appendTo="body" header="Vereinbarung bearbeiten">
            <p-editor [style]="{ height: '200px' }" formControlName="pciTableChange"></p-editor>
            <ng-template pTemplate="footer">
                <div class="grid">
                    <div class="col-8"></div>
                    <div class="col-4">
                        <button (click)="changePrecontractInformation()" class="custom-save-button"
                            icon="pi pi-paperclip" label="Hinzufügen" pButton type="button"></button>
                        <button (click)="closePciEditPopup()" class="custom-save-button p-button-danger"
                            icon="pi pi-times" label="Abbrechen" pButton type="button"></button>
                    </div>
                </div>
            </ng-template>
        </p-dialog>
    </form>
</app-sidebar>
