import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {HomeComponent} from "./home/home.component";
import {DataProtectionComponent} from "./data-protection/data-protection.component";
import {ContractComponent} from "./contract/contract.component";
import {NewCarComponent} from "./new-car/new-car.component";
import {UsedCarComponent} from "./used-car/used-car.component";
import {NewCamperComponent} from "./new-camper/new-camper.component";
import {UsedCamperComponent} from "./used-camper/used-camper.component";
import {OldtimerComponent} from "./oldtimer/oldtimer.component";
import {SettingsComponent} from "./settings/settings.component";
import {DayAdmissionComponent} from "./day-admission/day-admission.component";
import {OrderconfirmationComponent} from "./orderconfirmation/orderconfirmation.component";
import {NewOrderconfirmationComponent} from "./new-orderconfirmation/new-orderconfirmation.component";
import {AuthGuard} from "../auth/guards/auth.guard";
import { UserManagementComponent } from "./user-management/user-management.component";
import { NewUserComponent } from "./user-management/new-user/new-user.component";
import {PriceConfirmationComponent} from "./price-confirmation/price-confirmation.component";

const routes: Routes = [
    {
        path: "home",
        canActivate: [AuthGuard],
        component: HomeComponent,
    },
    {
        path: "data-protection",
        canActivate: [AuthGuard],
        component: DataProtectionComponent,
    },
    {
        path: "orderconfirmation",
        canActivate: [AuthGuard],
        component: OrderconfirmationComponent,
    },
    {
        path: "new-orderconfirmation",
        canActivate: [AuthGuard],
        component: NewOrderconfirmationComponent,
    },
    {
        path: "price-confirmation",
        canActivate: [AuthGuard],
        component: PriceConfirmationComponent,
    },
    {
        path: "contract",
        canActivate: [AuthGuard],
        component: ContractComponent,
    },
    {
        path: "new-car",
        canActivate: [AuthGuard],
        component: NewCarComponent,
    },
    {
        path: "used-car",
        canActivate: [AuthGuard],
        component: UsedCarComponent,
    },
    {
        path: "day-admission",
        canActivate: [AuthGuard],
        component: DayAdmissionComponent,
    },
    {
        path: "new-camper",
        canActivate: [AuthGuard],
        component: NewCamperComponent,
    },
    {
        path: "used-camper",
        canActivate: [AuthGuard],
        component: UsedCamperComponent,
    },
    {
        path: "oldtimer",
        canActivate: [AuthGuard],
        component: OldtimerComponent,
    },
    {
        path: "new-car/:type/:id",
        canActivate: [AuthGuard],
        component: NewCarComponent,
    },
    {
        path: "used-car/:type/:id",
        canActivate: [AuthGuard],
        component: UsedCarComponent,
    },
    {
        path: "day-admission/:type/:id",
        canActivate: [AuthGuard],
        component: DayAdmissionComponent,
    },
    {
        path: "new-camper/:type/:id",
        canActivate: [AuthGuard],
        component: NewCamperComponent,
    },
    {
        path: "used-camper/:type/:id",
        canActivate: [AuthGuard],
        component: UsedCamperComponent,
    },
    {
        path: "oldtimer/:type/:id",
        canActivate: [AuthGuard],
        component: OldtimerComponent,
    },
    {
        path: "settings",
        canActivate: [AuthGuard],
        component: SettingsComponent,
    },
    {
        path: "user-management",
        canActivate: [AuthGuard],
        component: UserManagementComponent,
    },
    {
        path: "user-management/edit/:id",
        canActivate: [AuthGuard],
        component: NewUserComponent,
    },
    {
        path: "new-user",
        canActivate: [AuthGuard],
        component: NewUserComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class DashboardRoutingModule {
}
