import {PreContractInformation} from "./preContractInformation";
import {FormType} from "./formType";

export class Contract {
    //Neuwagen
    formtype = '';
    contracttype = '';
    vehicle = '';
    buyersalutation = '';
    buyerfirstname = '';
    buyerlastname = '';
    buyerstreet = '';
    buyerzip = '';
    buyercity = '';
    buyerphone = '';
    buyermobile = '';
    buyeremail = '';
    brand = '';
    equipment = '';
    engine = '';
    color = '';
    interior = '';
    tires = '';
    deliverydate = '';
    vehiclepricenet: number = 0;
    vehiclepricegross: number = 0;
    additionalsnet: number = 0;
    additionalsgross: number = 0;
    listpricenet: number = 0;
    listpricegross: number = 0;
    discountnet: number = 0;
    discountgross: number = 0;
    housepricenet: number = 0;
    housepricegross: number = 0;
    transfernet: number = 0;
    transfergross: number = 0;
    endpricenet: number = 0;
    endpricegross: number = 0;
    paymenttype = '';
    vatReportable: boolean = false;
    precontractinformations: any[] = [];

    //Gebrauchtwagen
    vin = '';
    mileage = '';
    firstregistration = '';
    mainexaminationemissiontest = '';
    previousowner = '';

    id?: string;
    templateidprecontract = '';
    templateidcontract = '';
    templateidcontractattachment = '';
    contractdate = '';
}
