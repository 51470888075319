import { Injectable } from '@angular/core';
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class CalculationService {

  constructor() { }

  /*
     * Start Price calculations if no tax is selected
     */
  // nimmt den Wert aus "Fahrzeugpreis" und berechnet die nachfolgenden Werte
  priceChangedForNoTaxCalculation(contractForm: FormGroup) {
    const input = contractForm.get("vehiclepricegross")?.value;
    if (input) {
      contractForm.patchValue({
        vehiclepricegross: Number((input).toFixed(2)),
      });
    } else {
      contractForm.patchValue({
        vehiclepricegross: 0,
      });
    }
    this.endpriceCalculationNoTax(contractForm);
  }

  // nimmt den Wert aus "Fahrzeugpreis" und berechnet die nachfolgenden Werte
  discountChangedForNoTaxCalculation(contractForm: FormGroup) {
    const input = contractForm.get("discountgross")?.value;
    if (input) {
      contractForm.patchValue({
        discountgross: Number((input).toFixed(2)),
      });
    } else {
      contractForm.patchValue({
        discountgross: 0,
      });
    }
    this.endpriceCalculationNoTax(contractForm);
  }

  // nimmt den Wert aus "Fahrzeugpreis" und berechnet die nachfolgenden Werte
  transferChangedForNoTaxCalculation(contractForm: FormGroup) {
    const input = contractForm.get("transfergross")?.value;
    if (input) {
      contractForm.patchValue({
        transfergross: Number((input).toFixed(2)),
      });
    } else {
      contractForm.patchValue({
        transfergross: 0,
      });
    }
    this.endpriceCalculationNoTax(contractForm);
  }

  endpriceCalculationNoTax(contractForm: FormGroup) {
    let price = Number(contractForm.get("vehiclepricegross")?.value);
    let discount = Number(contractForm.get("discountgross")?.value);
    let transfer = Number(contractForm.get("transfergross")?.value);

    if (isNaN(price)) {
      price = 0;
    }

    if (isNaN(discount)) {
      discount = 0;
    }

    if (isNaN(transfer)) {
      transfer = 0;
    }

    contractForm.patchValue({
      endpricegross: Number(((price + transfer) - discount).toFixed(2)),
    });
  }
  /*
   * End Price calculations if no tax is selected
   */


  /*
  * Start Price calculations if tax is selected
   */
  priceNetChanged(contractForm: FormGroup) {
    const input = contractForm.get("vehiclepricenet")?.value;
    if (input) {
      contractForm.patchValue({
        vehiclepricenet: Number((input).toFixed(2)),
        vehiclepricegross: Number(this.netToGrossCalculation(input).toFixed(2)),
      });
    } else {
      contractForm.patchValue({
        vehiclepricenet: 0,
        vehiclepricegross: 0,
      });
    }
    this.endpriceCalculation(contractForm);
  }

  priceGrossChanged(contractForm: FormGroup) {
    const input = contractForm.get("vehiclepricegross")?.value;
    if (input) {
      contractForm.patchValue({
        vehiclepricegross: Number((input).toFixed(2)),
        vehiclepricenet: Number(this.grossToNetCalculation(input).toFixed(2)),
      });
    } else {
      contractForm.patchValue({
        vehiclepricegross: 0,
        vehiclepricenet: 0,
      });
    }
    this.endpriceCalculation(contractForm);
  }

  discountNetChanged(contractForm: FormGroup) {
    const input = contractForm.get("discountnet")?.value;
    if (input) {
      contractForm.patchValue({
        discountnet: Number((input).toFixed(2)),
        discountgross: Number(this.netToGrossCalculation(input).toFixed(2)),
      });
    } else {
      contractForm.patchValue({
        discountnet: 0,
        discountgross: 0,
      });
    }
    this.endpriceCalculation(contractForm);
  }

  discountGrossChanged(contractForm: FormGroup) {
    const input = contractForm.get("discountgross")?.value;
    if (input) {
      contractForm.patchValue({
        discountgross: Number((input).toFixed(2)),
        discountnet: Number(this.grossToNetCalculation(input).toFixed(2)),
      });
    } else {
      contractForm.patchValue({
        discountgross: 0,
        discountnet: 0,
      });
    }
    this.endpriceCalculation(contractForm);
  }

  transferNetChanged(contractForm: FormGroup) {
    const input = contractForm.get("transfernet")?.value;
    if (input) {
      contractForm.patchValue({
        transfernet: Number((input).toFixed(2)),
        transfergross: Number(this.netToGrossCalculation(input).toFixed(2)),
      });
    } else {
      contractForm.patchValue({
        transfernet: 0,
        transfergross: 0,
      });
    }
    this.endpriceCalculation(contractForm);
  }

  transfergrossChanged(contractForm: FormGroup) {
    const input = contractForm.get("transfergross")?.value;
    if (input) {
      contractForm.patchValue({
        transfergross: Number((input).toFixed(2)),
        transfernet: Number(this.grossToNetCalculation(input).toFixed(2)),
      });
    } else {
      contractForm.patchValue({
        transfergross: 0,
        transfernet: 0,
      });
    }
    this.endpriceCalculation(contractForm);
  }

  endpriceCalculation(contractForm: FormGroup) {
    let priceGross = Number(contractForm.get("vehiclepricegross")?.value);
    let discountGross = Number(contractForm.get("discountgross")?.value);
    let transferGross = Number(contractForm.get("transfergross")?.value);
    let priceNet = Number(contractForm.get("vehiclepricenet")?.value);
    let discountNet = Number(contractForm.get("discountnet")?.value);
    let transferNet = Number(contractForm.get("transfernet")?.value);

    if (isNaN(priceGross)) {
      priceGross = 0;
    }

    if (isNaN(priceNet)) {
      priceNet = 0;
    }

    if (isNaN(discountGross)) {
      discountGross = 0;
    }

    if (isNaN(discountNet)) {
      discountNet = 0;
    }

    if (isNaN(transferGross)) {
      transferGross = 0;
    }

    if (isNaN(transferNet)) {
      transferNet = 0;
    }

    contractForm.patchValue({
      endpricegross: Number((priceGross + transferGross - discountGross).toFixed(2)),
      endpricenet: Number((priceNet + transferNet - discountNet).toFixed(2)),
    });
  }

  // Netto zu brutto Berechnung
  netToGrossCalculation(netValue: number) {
    return netValue * 1.19;
  }

  // Brutto zu netto Berechnung
  grossToNetCalculation(grossValue: number) {
    return grossValue / 1.19;
  }
}
