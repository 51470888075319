import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { lastValueFrom } from "rxjs";
import { Informationstemplate } from "../models/informationstemplate";
import { HttpClient } from "@angular/common/http";
import {CreateInformationstemplate} from "../models/createinformationstemplate";

@Injectable({
    providedIn: 'root',
})
export class SettingsService {

    constructor(private http: HttpClient) {
    }

    createInformation(informationsTemplate: CreateInformationstemplate): Promise<any> {
        const result$ = this.http.post<any>(environment.host + 'informationstemplate/', informationsTemplate);
        return lastValueFrom(result$);
    }

    listAllInformations(): Promise<any> {
        const result$ = this.http.get<any>(environment.host + 'informationstemplate');
        return lastValueFrom(result$);
    }

    updateInformation(informationsTemplate: Informationstemplate): Promise<any> {
        const result$ = this.http.put<any>(environment.host + 'informationstemplate/' + informationsTemplate.id, informationsTemplate);
        return lastValueFrom(result$);
    }

    deleteInformation(object: any) {
        const result$ = this.http.delete<any>(environment.host + 'informationstemplate/' + object.id);
        return lastValueFrom(result$);
    }
}
