import { Injectable } from "@angular/core";
import { RenderTemplate } from "../models/renderTemplate";
import { Observable, lastValueFrom } from "rxjs";
import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { handleError } from "../tools/error-helper";
import { MessageService } from "primeng/api";
import { ContractService } from "./contractService";
import { Renderinformations } from "../models/renderinformations";
import { DocumentType } from "../models/documentType";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root',
})
export class RenderPdfService {

    private httpClient: HttpClient;
    templateIds: any;
    dataRi: Renderinformations[] = [];
    constructor(private handler: HttpBackend, private http: HttpClient, private messageService: MessageService, private contractService: ContractService, private router: Router) {
        this.httpClient = new HttpClient(handler);
    }

    // async renderPdf(endpoint: string, data: RenderTemplate): Promise<any> {
    //     try {
    //         const httpOptions = {
    //             headers: new HttpHeaders({ "Authorization": environment.carboneApiKey, 'Content-Type': 'application/json', "carbone-version": "4" }),
    //         };
    //         data["convertTo"] = "pdf";
    //         for (let index = 0; index < data.data.precontractinformations.length; index++) {
    //             let content = data.data.precontractinformations[index].content;
    //             let description = data.data.precontractinformations[index].description;
    //
    //             if (content && content.includes("<")) {
    //                 content = content.replace(/(<([^>]+)>)/gi, "");
    //             }
    //
    //             if (description && description.includes("<")) {
    //                 description = description.replace(/(<([^>]+)>)/gi, "");
    //             }
    //
    //             if (content && content.includes("&amp;")) {
    //                 content = content.replace(/&amp;/g, "&");
    //             }
    //
    //             data.data.precontractinformations[index].content = content;
    //             data.data.precontractinformations[index].description = description;
    //         }
    //
    //         const result$ = this.httpClient.post(endpoint, data, httpOptions);
    //         return await lastValueFrom(result$);
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }

    async downloadPdf(renderId: string) {
        try {
            this.httpClient.get('https://render.carbone.io/render/' + renderId,
                { responseType: 'blob' }
            ).subscribe((resoponse: Blob) => {
                var file = new Blob([resoponse], { type: 'application/pdf' })
                var fileURL = URL.createObjectURL(file);
                var a = document.createElement('a');
                a.href = fileURL;
                a.target = '_blank';
                document.body.appendChild(a);
                a.click();
                // a.dispatchEvent(
                //     new MouseEvent('click', {
                //     bubbles: true,
                //     cancelable: true,
                //     view: window,
                //     })
                // );
            });
        } catch (e) {
            console.error(e);
        }
    }



    getTemplateById(id: string): Promise<any> | null {
        if (id) {
            console.log(this.http.get<any>('https://api.carbone.io/template/' + id));
        }
        return null;
    }

    // async getTemplateIdByName(templateName: string) {
    //     try {
    //         this.templateIds = await this.contractService.listAllRenderInformations();
    //         this.dataRi = Object.assign(this.templateIds.data);
    //         if (this.dataRi.length > 0) {
    //             for (let i = 0; i < this.dataRi.length; i++) {
    //                 if (this.dataRi[i].formname === templateName) {
    //                     return this.dataRi[i].rendertemplateid;
    //                 }
    //             }
    //         }
    //         return '';
    //     } catch (error) {
    //         this.messageService.add({
    //             severity: 'error',
    //             summary: 'Fehler',
    //             detail: 'Die Liste konnte nicht geladen werden.',
    //         });
    //         handleError(error, this.router);
    //         return '';
    //     }
    // }
}
