<app-sidebar>
    <div class="px-4 py-8 md:px-6 lg:px-8">
        <div class="text-900 text-3xl font-bold mb-3">Auftragsbestätigung</div>
        <div class="grid mt-7">
            <div class="col-12 md:col-4">
                <div class="shadow-1 p-5 surface-card text-center border-round">
                    <div class="border-circle bg-purple-50 p-4 text-purple-600 inline-flex justify-content-center align-items-center mb-4">
                        <img class="card-image" src="assets/images/order-confirmation.png">
                    </div>
                    <div class="text-900 font-medium text-2xl mb-4">Neue Auftragsbestätigung</div>
                    <button (click)="navigateToOrderConfirmation()" pButton pRipple label="AB anlegen"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="px-4 py-8 md:px-6 lg:px-8">
        <div class="text-900 text-3xl font-bold mb-3">Bitte den gewünschten Vertrag auswählen</div>
        <div class="grid mt-7">
            <div class="col-12 md:col-4">
                <div class="shadow-1 p-5 surface-card text-center border-round">
                    <div class="border-circle bg-purple-50 p-4 text-purple-600 inline-flex justify-content-center align-items-center mb-4">
                        <img class="card-image" src="assets/images/new-car.png">
                    </div>
                    <div class="text-900 font-medium text-2xl mb-4">Neuwagen</div>
                    <button (click)="navigateToNewCar()" pButton pRipple label="Vertrag anlegen"></button>
                </div>
            </div>
            <div class="col-12 md:col-4">
                <div class="shadow-1 p-5 surface-card text-center border-round">
                    <div class="border-circle bg-purple-50 p-4 text-purple-600 inline-flex justify-content-center align-items-center mb-4">
                        <img class="card-image" src="assets/images/used-car.png">
                    </div>
                    <div class="text-900 font-medium text-2xl mb-4">Gebrauchtwagen</div>
                    <button (click)="navigateToUsedCar()" pButton pRipple label="Vertrag anlegen"></button>
                </div>
            </div>

<!--            <div class="col-12 md:col-4">-->
<!--                <div class="shadow-1 p-5 surface-card text-center border-round">-->
<!--                    <div class="border-circle bg-purple-50 p-4 text-purple-600 inline-flex justify-content-center align-items-center mb-4">-->
<!--                        <img class="card-image" src="assets/images/day-admission.png">-->
<!--                    </div>-->
<!--                    <div class="text-900 font-medium text-2xl mb-4">Tageszulassung/Vorführwagen</div>-->
<!--                    <button (click)="navigateToDayAdmission()" pButton pRipple label="Vertrag anlegen"></button>-->
<!--                </div>-->
<!--            </div>-->
            <div class="col-12 md:col-4">
                <div class="shadow-1 p-5 surface-card text-center border-round">
                    <div class="border-circle bg-purple-50 p-4 text-purple-600 inline-flex justify-content-center align-items-center mb-4">
                        <img class="card-image" src="assets/images/new-camper.png">
                    </div>
                    <div class="text-900 font-medium text-2xl mb-4">Reisemobil neu</div>
                    <button (click)="navigateToNewCamper()" pButton pRipple label="Vertrag anlegen"></button>
                </div>
            </div>
            <div class="col-12 md:col-4">
                <div class="shadow-1 p-5 surface-card text-center border-round">
                    <div class="border-circle bg-purple-50 p-4 text-purple-600 inline-flex justify-content-center align-items-center mb-4">
                        <img class="card-image" src="assets/images/used-camper.png">
                    </div>
                    <div class="text-900 font-medium text-2xl mb-4">Reisemobil gebraucht</div>
                    <button (click)="navigateToUsedCamper()" pButton pRipple label="Vertrag anlegen"></button>
                </div>
            </div>
            <div class="col-12 md:col-4">
                <div class="shadow-1 p-5 surface-card text-center border-round">
                    <div class="border-circle bg-purple-50 p-4 text-purple-600 inline-flex justify-content-center align-items-center mb-4">
                        <img class="card-image" src="assets/images/oldtimer.png">
                    </div>
                    <div class="text-900 font-medium text-2xl mb-4">Oldtimer</div>
                    <button (click)="navigateToOldtimer()" pButton pRipple label="Vertrag anlegen"></button>
                </div>
            </div>
        </div>
    </div>
</app-sidebar>
