import {Component, Input, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {AuthService} from "../../auth/auth.service";
import {getUserFromToken} from "../models/global.constants";
import {CurrentUser} from "../models/currentUser";
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
    @Input() public menuActive: boolean | undefined;
    public menuItems: MenuItem[] = [];
    public userMenu: MenuItem[] = [];
    public currentUser: CurrentUser = getUserFromToken();
    decodeToken:any;
    menu: boolean;
    isAdmin: boolean = false;

    constructor(private authService: AuthService) {
        this.menu = true;
    }

    public ngOnInit(): void {
        const token = localStorage.getItem('PUW_CM_JWT');
		const helper = new JwtHelperService();
        if (token) {
            this.decodeToken = helper.decodeToken(token);
            this.isAdmin = this.decodeToken.admin;
        }

        // Start Menuitems
        if(this.isAdmin) {
            this.menuItems = [
                {
                    label: 'Home',
                    icon: 'pi pi-home',
                    routerLink: '/home',
                },
                {
                    label: 'AB',
                    icon: 'pi pi-file',
                    routerLink: '/orderconfirmation',
                },
                {
                    label: 'Verträge',
                    icon: 'pi pi-copy',
                    routerLink: '/contract',
                    disabled: true,
                },
                {
                    label: 'Preisbestätigung',
                    icon: 'pi pi-euro',
                    routerLink: '/price-confirmation',
                    disabled: true,
                },
                {
                    label: 'Benutzerverwaltung',
                    icon: 'pi pi-user',
                    routerLink: '/user-management',
                    disabled: true,
                },
                {
                    label: 'Einstellungen',
                    icon: 'pi pi-cog',
                    routerLink: '/settings',
                    disabled: true,
                },
               
            ];
        } else {
            this.menuItems = [
                {
                    label: 'Home',
                    icon: 'pi pi-home',
                    routerLink: '/home',
                },
                {
                    label: 'AB',
                    icon: 'pi pi-file',
                    routerLink: '/orderconfirmation',
                },
                {
                    label: 'Verträge',
                    icon: 'pi pi-copy',
                    routerLink: '/contract',
                    disabled: true,
                },
            ];
        }

        this.userMenu = [{
                label: 'Abmelden',
                icon: 'pi pi-fw pi-sign-out',
                command: () => this.logout()
            }
        ];
    }

    onMenuIcon() {
        this.menu = !this.menu;
    }

    public activateMenu(event: any): void {
        let node;
        let offsetParent;
    
        if (event.target.classList.contains('p-submenu-header')) {
            node = 'submenu';
        } else if (event.target.tagName === 'SPAN') {
            let targetElement = event.target;
            offsetParent = targetElement.offsetParent || targetElement.parentElement;
            node = offsetParent;
        } else {
            offsetParent = null;
            node = offsetParent;
        }
    
        if (node && node !== 'submenu' && offsetParent !== null) {
            let menuitem = document.getElementsByClassName('p-menuitem-active');
            for (let i = 0; i < menuitem.length; i++) {
                menuitem[i].classList.remove('p-menuitem-active');
            }
            offsetParent.classList.add('p-menuitem-active');
        }
    }

    logout(): void {
        this.authService.logout();
    }
}
