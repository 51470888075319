import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpBackend, HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Base64Mail} from "../models/base64Mail";
import {MessageService} from "primeng/api";
import { Priceconfirmation } from "../models/priceconfirmation";

@Injectable({
    providedIn: 'root',
})
export class PriceConfirmationService {

    private httpClient: HttpClient;
    Reportresponse: any;
    base64data: Base64Mail = new Base64Mail();

    constructor(private handler: HttpBackend, 
                private messageService: MessageService) {
        this.httpClient = new HttpClient(handler);
    }

    createReport(report: any): Observable<Blob> {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Basic ' + btoa("admin:GX2TXuL7ef6i5NS3b7SL"),
            'Content-Type': 'application/json',
          }),
          responseType: 'blob' as 'json',
        };
      
        return this.httpClient.post(environment.jsreportEndpoint + "/api/report", report, httpOptions) as Observable<Blob>;
      }
      
      async downloadAndSendpriceConfirmation(priceconfirmation: Priceconfirmation) {
        try {
          const report = {
            data: {
              date: priceconfirmation.date,
              vehicle: priceconfirmation.vehicle,
              numberPlate: priceconfirmation.numberPlate,
              vin: priceconfirmation.numberPlate,
              price: priceconfirmation.price,
            },
            template: {
              name: 'preisbestaetigung',
            }
          }
      
          const reportObservable: Observable<Blob> = this.createReport(report);
          reportObservable.subscribe(
            (reportBlob: Blob) => {
              if (reportBlob instanceof Blob) {
              
                this.convertBlobToBase64(reportBlob).then((base64data: Base64Mail) => {
                  this.downloadBase64Data(base64data, 'preisbestaetigung.pdf');
      
                  this.messageService.add({
                    severity: 'success',
                    summary: 'Erfolg',
                    detail: 'Der Preisbestätigungsbericht wurde erfolgreich heruntergeladen.',
                  });
                }).catch((error) => this.handleError(error, 'Es ist ein Fehler beim Konvertieren aufgetreten:'));
              }
            },
            (error) => this.handleError(error, 'Es ist ein Fehler beim Erstellen des Berichts aufgetreten:')
          );
        } catch (error) {
          this.handleError(error, 'Es ist ein allgemeiner Fehler aufgetreten:');
        }
      }
      

async convertBlobToBase64(blob: Blob): Promise<Base64Mail> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64data = reader.result?.toString()?.replace("data:application/pdf;base64,", "");
      if (base64data) {
        resolve({
          base64: base64data,
          subject: '',
          receiverEmail: '',
          sellerEmail: '',
        });
      } else {
        reject(new Error('Error converting Blob to Base64'));
      }
    };
    reader.onerror = (event) => reject(new Error('File could not be read: '));
    reader.readAsDataURL(blob);
  });
}

downloadBase64Data(base64data: Base64Mail, fileName: string): void {
  const link = document.createElement('a');
  link.href = `data:application/pdf;base64,${base64data.base64}`;
  link.download = fileName;
  link.click();
}

handleError(error: any, errorMessagePrefix: string): void {
  console.error(errorMessagePrefix, error);
  this.messageService.add({
    severity: 'error',
    summary: 'Fehler',
    detail: errorMessagePrefix + ' ' + error,
  });
}

}
