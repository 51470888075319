import {Component, HostListener, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ContractService} from "../../shared/service/contractService";
import {MessageService} from "primeng/api";
import {Contract} from "../../shared/models/contract";
import {ActivatedRoute, Router} from "@angular/router";
import {handleError} from "../../shared/tools/error-helper";
import {SettingsService} from "../../shared/service/settingsService";
import {AuthService} from 'src/app/auth/auth.service';
import {Informationstemplate} from 'src/app/shared/models/informationstemplate';
import {PatchService} from "../../shared/service/patch.service";
import {CalculationService} from "../../shared/service/calculation.service";
import {FormType} from "../../shared/models/formType";

@Component({
    selector: 'app-oldtimer',
    templateUrl: './oldtimer.component.html',
    styleUrls: ['./oldtimer.component.scss']
})
export class OldtimerComponent implements OnInit {
    templateinformationsnewcar: any;
    dataOldtimerCar: any[] = [];
    newTemplateIds: any[] = [];
    text: string = '';
    contractId: string = '';
    selectedValue: string = 'val2';
    type: string = '';
    loading: boolean = false;
    contractForm: FormGroup;
    pciChangeForm: FormGroup;
    activeState: boolean[] = [true, false, false];
    isAgreementVisible: boolean = false;
    preContractInformations: any[] = [];
    newContractInformations: any[] = [];
    contractInformationsResponse: any;
    contractDataPaymentType: any = [];
    contractDataType: any = [];
    isDetailView: boolean = false;
    isConnect: boolean = false;
    contractIsCreated: boolean = false;
    styleDownloadFileCard = { background: '#f2f4f7', marginBottom: '2em' };
    contractInformations: Contract = new Contract();
    isAgreementSelectionVisible: boolean = false;
    selectedAgreements: any;
    showPciEditField: boolean = false;
    contract: boolean = false;
    vatReportable: boolean = false;
    pciToEdit: string = '';
    preContractInfoId!: any;
    taxselect!: FormGroup;
    loadingPreContract: boolean = false;
    loadingContract: boolean = false;
    loadingAttachment: boolean = false;
    loadingOrderConfirmation: boolean = false;
    userId: any;
    sellerFirstName: any;
    sellerLastName: any;
    sellerEmailAddress: any;
    userDetail: any;
    newCreated: any;
    informationsTemplateForm: FormGroup;

    constructor(private contractService: ContractService, private messageService: MessageService, private activatedRoute: ActivatedRoute, private calcService: CalculationService,
                private patchService: PatchService, private router: Router, private settingsService: SettingsService, private authService: AuthService) {
        this.contractDataPaymentType = [
            { name: "Bar", code: "br", value: "Bar" },
            { name: "Finanzierung", code: "fr", value: "Finanzierung" },
            { name: "Leasing", code: "ls", value: "Leasing" },
            { name: "Miete", code: "mt", value: "Miete" },
        ];

        this.contractDataType = [
            { name: "Privat", code: "pr", value: "privat" },
            { name: "Gewerblich", code: "ge", value: "gewerblich" },
        ];

        this.pciChangeForm = new FormGroup({
            pciTableChange: new FormControl(""),
        });

        this.taxselect = new FormGroup({
            value: new FormControl(""),
        });

        this.informationsTemplateForm = new FormGroup({
            content: new FormControl("", Validators.required),
            description: new FormControl("", Validators.required),
        });

        this.contractForm = new FormGroup({
            id: new FormControl(""),
            contracttype: new FormControl(null, Validators.required),
            vehicle: new FormControl("", Validators.required),
            buyersalutation: new FormControl("", Validators.required),
            buyerfirstname: new FormControl(""),
            buyerlastname: new FormControl("", Validators.required),
            buyerstreet: new FormControl("", Validators.required),
            buyerzip: new FormControl("", Validators.required),
            buyercity: new FormControl("", Validators.required),
            buyerphone: new FormControl(""),
            buyermobile: new FormControl(""),
            buyeremail: new FormControl(""),
            brand: new FormControl("", Validators.required),
            equipment: new FormControl("", Validators.required),
            engine: new FormControl("", Validators.required),
            color: new FormControl("", Validators.required),
            interior: new FormControl("", Validators.required),
            tires: new FormControl(""),
            vin: new FormControl("", Validators.required),
            mileage: new FormControl(""),
            firstregistration: new FormControl(""),
            mainexaminationemissiontest: new FormControl(""),
            previousowner: new FormControl(""),
            deliverydate: new FormControl("", Validators.required),
            contractdate: new FormControl("", Validators.required),
            vehiclepricenet: new FormControl(Number),
            vehiclepricegross: new FormControl(Number),
            endpricenet: new FormControl(Number),
            endpricegross: new FormControl(Number),
            paymenttype: new FormControl(""),

            precontractinformations: new FormControl(""),
            formtype: new FormControl(""),
            vatReportable: new FormControl(false),
            pciTableChange: new FormControl("")
        });

        this.sellerFirstName = this.authService.getFirstNameFromToken();
        this.sellerLastName = this.authService.getLastNameFromToken();
        this.sellerEmailAddress = this.authService.getEmailFromToken();
        this.userId = this.authService.getUserIdFromToken();

        this.userDetail = {sellerFirstName: this.sellerFirstName, sellerLastName: this.sellerLastName, sellerEmailAddress: this.sellerEmailAddress}
    }

    async ngOnInit(): Promise<void> {
        this.activatedRoute.params.subscribe((params) => {
            this.contractId = params['id'];
            this.type = params['type'];
        });

        if (this.contractId) {
            await this.loadContractById(this.contractId);
            this.contractForm.get('id')?.setValue(this.contractId);
        }

        await this.loadUsedCarTemplates();
    }

    @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler() {
        if (this.isAgreementVisible) {
            this.closeAgreementPopup();
        }
    }

    async loadContractById(contractId: string) {
        try {
            if(this.type === "view") {
                this.contractForm.disable();
                this.contractIsCreated = true;
                this.isDetailView = true;
            } else {
                this.contractForm.enable();
                this.contractIsCreated = false;
                this.isDetailView = false;
            }
            this.contractInformations = await this.contractService.getCarEntry(contractId);
            this.patchValues(this.contractInformations);
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Fehler',
                detail: 'Der Vertrag konnte nicht geladen werden.',
            });
            handleError(error, this.router);
        }
    }

    async loadUsedCarTemplates(): Promise<void> {
        try {
            this.loading = true;
            this.templateinformationsnewcar = await this.settingsService.listAllInformations();
            const filteredTemplates = this.templateinformationsnewcar.filter((template: any) => template.templateType === 'Oldtimer');
            this.getTemplatesData(filteredTemplates)
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Fehler',
                detail: 'Die Liste konnte nicht geladen werden.',
            });
            handleError(error, this.router);
        }
        this.loading = false;
    }

    getTemplatesData(templateinformations: any) {
        this.dataOldtimerCar = [...templateinformations];
        if (this.dataOldtimerCar.length > 0) {
            for (let i = 0; i < this.dataOldtimerCar.length; i++) {
                // Check if the current element has the expected properties
                if (this.dataOldtimerCar[i].templateType === 'Oldtimer') {
                    this.preContractInformations.push(this.dataOldtimerCar[i]);
                }
                if (!this.contractId) {
                    if (this.dataOldtimerCar[i].default && this.dataOldtimerCar[i].templateType === 'Oldtimer') {
                        this.newContractInformations.push(this.dataOldtimerCar[i]);
                    }
                }
            }

            if (!this.contractId) {
                this.newTemplateIds = this.newContractInformations
                .filter(item => item.default) // Optional: Filter only items with 'default' property
                .map(item => item.id.toString());
            }
        }
    }

    addAgreementTemplateToContract() {
        this.closeAgreementSelectionPopup();
        let cId = this.contractId;
        this.newContractInformations.push(...this.selectedAgreements.map(function (agr: Informationstemplate) {
            return {
                description: agr.description,
                content: agr.content,
                contract: cId
            }
        }));
        this.newTemplateIds = this.newContractInformations;
    }

    onTextChange(event: any) {
        this.text = event;
        this.contractForm.get("precontractinformations")?.setValue(this.text);
    }

    createAgreementToTable() {
        this.closeAgreementPopup();
        this.newContractInformations.push({
            content: this.text,
            description: null,
            contract: this.contractId
        });
        this.newTemplateIds = this.newContractInformations;
    }

    toggle(index: number) {
        this.activeState[index] = !this.activeState[index];
    }

    showAgreementPopup() {
        this.isAgreementVisible = true;
    }

    closeAgreementPopup() {
        this.isAgreementVisible = false;
    }

    showPciEditPopup() {
        this.showPciEditField = true;
    }

    closePciEditPopup() {
        this.showPciEditField = false;
    }

    closeAgreementSelectionPopup() {
        this.isAgreementSelectionVisible = false;
    }

    editPrecontractInformation(preContractInformation: any) {
        this.showPciEditPopup();
        this.pciToEdit = preContractInformation.content;
        this.contractForm.controls['pciTableChange'].setValue(this.pciToEdit);
        this.pciChangeForm.patchValue({
            pciTableChange: this.pciToEdit,
        })
    }

    onChange(e: any): void {
        if (e == 'val1') {
            this.vatReportable = true;
        } else {
            this.vatReportable = false;
            this.taxselect.get('value')?.setValue('no-tax');
        }
    }

    //TODO: copy to generic class and use it in all components
    async editEntryInContractTable() {
        for (let i = 0; i < this.newContractInformations.length; i++) {
            if (this.newContractInformations[i].content === this.pciToEdit) {
                this.newContractInformations[i].content = this.contractForm.get("pciTableChange")?.value;
            }
        }
        this.closePciEditPopup();
    }

    removePrecontractInformation(preContractInformation: any) {
        this.newContractInformations.splice(this.newContractInformations.indexOf(preContractInformation), 1);
        this.text = '';
    }

    priceChangedForNoTaxCalculation() {
        this.calcService.priceChangedForNoTaxCalculation(this.contractForm);
    }

    endpriceCalculationNoTax() {
       this.calcService.endpriceCalculation(this.contractForm);
    }

    priceNetChanged() {
        this.calcService.priceNetChanged(this.contractForm);
    }

    priceGrossChanged() {
        this.calcService.priceGrossChanged(this.contractForm);
    }

    async save() {
        try {
            if (this.contractForm.invalid) {
                this.contractForm.markAllAsTouched();
                return;
            }
            await this.applyValues();

            //get the current values from the table and save it to contract
            this.patchLatestAgreementsToContract();

            this.contractForm.disable();
            this.contractInformationsResponse = await this.contractService.createCarEntry(this.contractInformations);

            this.messageService.add({
                severity: 'success',
                summary: 'Erfolg',
                detail: 'Der Vertrag wurde erfolgreich angelegt.'
            });
            this.contractIsCreated = true;
        } catch (error) {
            this.contractIsCreated = false;
            this.messageService.add({
                severity: 'error',
                summary: 'Fehler',
                detail: 'Ihr vertrag konnte nicht erzeugt werden.',
            });
        }
    }

    async update() {
        try {
            await this.applyValues();

            //delete all the precontractinformations from this contract id
            await this.contractService.deleteContractAgreementsByContractId(this.contractId);

            //get the current values from the table and patch it to contract
            this.patchLatestAgreementsToContract();

            this.contractInformationsResponse = await this.contractService.updateCarEntry(this.contractInformations);
            this.messageService.add({
                severity: 'success',
                summary: 'Erfolg',
                detail: 'Der Vertrag wurde erfolgreich aktualisiert.'
            });
            setTimeout(() => {
                this.router.navigateByUrl("contract");
            }, 500);

        } catch (error) {
            this.contractIsCreated = false;
            this.messageService.add({
                severity: 'error',
                summary: 'Fehler',
                detail: 'Ihr Vertrag konnte nicht aktualisiert werden.',
            });
        }
    }

    patchLatestAgreementsToContract() {
        this.contractInformations.precontractinformations = this.newContractInformations;
    }

    async applyValues() {
        this.patchService.applyValues(this.contractForm, this.vatReportable, FormType.Oldtimer, this.contractId).then(contract => {
            this.contractInformations = contract;
        });
    }

    patchValues(contract: Contract) {
        if (this.contractInformations) {
            this.selectedValue = contract.vatReportable ? 'val1' : 'val2';
            this.vatReportable = contract.vatReportable;

            const contractFields: (keyof Contract)[] = [
                'contracttype', 'vehicle', 'buyersalutation', 'buyerfirstname', 'buyerlastname',
                'buyerstreet', 'buyerzip', 'buyercity', 'buyerphone', 'buyermobile',
                'buyeremail', 'brand', 'equipment', 'engine', 'color', 'interior', 'tires',
                'contractdate', 'deliverydate', 'vehiclepricenet', 'vehiclepricegross',
                'endpricenet', 'endpricegross',
                'paymenttype', 'formtype', 'vatReportable', 'vin', 'mileage',
                'firstregistration', 'mainexaminationemissiontest', 'previousowner'
            ];

            const contractFormValues: Partial<Record<keyof Contract, any>> = {};
            contractFields.forEach(field => {
                contractFormValues[field] = contract[field];
            });

            this.contractForm.patchValue(contractFormValues);

            this.newContractInformations = contract.precontractinformations;
            this.newTemplateIds = this.newContractInformations.map(info => info.id);
        }
    }

    async onDownloadReport(reportType: string) {
        try {
            switch (reportType) {
                case 'preContract':
                    this.loadingPreContract = true;
                    break;
                case 'contract':
                    this.loadingContract = true;
                    break;
                case 'attachment':
                    this.loadingAttachment = true;
                    break;
                case 'orderConfirmation':
                    this.loadingOrderConfirmation = true;
                    break;
                default:
                    console.error('Invalid report type:', reportType);
                    return;
            }

            if (reportType != null || reportType !== '') {
                if (this.contractId) {
                    this.contractInformationsResponse = this.contractInformations;
                }
                await this.contractService.downloadReport(this.contractInformationsResponse, reportType, true, true, true, this.isConnect, this.userDetail, 'Oldtimer');
                this.messageService.add({
                    severity: 'success',
                    summary: 'Erfolg',
                    detail: 'Der Bericht wurde erfolgreich heruntergeladen.'
                });

            } else {
                console.error('Invalid value for download.');
                this.messageService.add({
                    severity: 'error',
                    summary: 'Fehler',
                    detail: 'Ungültiger Wert für den Download.',
                });
            }

        } catch (error) {
            console.error('Fehler beim Herunterladen des Berichts:', error);

        } finally {
            switch (reportType) {
                case 'preContract':
                    this.loadingPreContract = false;
                    break;
                case 'contract':
                    this.loadingContract = false;
                    break;
                case 'attachment':
                    this.loadingAttachment = false;
                    break;
                case 'orderConfirmation':
                    this.loadingOrderConfirmation = false;
                    break;
            }
        }
    }

    checkState(state: boolean) {
        return state;
    }

    openSelectionPopup() {
        this.isAgreementSelectionVisible = true;
    }
}
