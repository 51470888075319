import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {MessageService} from "primeng/api";
import {handleError} from "../../shared/tools/error-helper";
import {Orderconfirmation} from "../../shared/models/orderconfirmation";
import { UserService } from 'src/app/shared/service/userService';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  public users: any;
 
  public disable = false;

  public decodeToken:any;

  isAdmin: boolean = false;

  constructor(private router: Router, private userService: UserService, private messageService: MessageService) {
  }

  async ngOnInit() {
    await this.loadAllUsers();
  }

  async loadAllUsers(): Promise<void> {
    try {
        this.users = await this.userService.listAllUsers();
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: 'Die Liste konnte nicht geladen werden.',
      });
      handleError(error, this.router);
    }
  }

  navigateToCreateUser() {
    this.router.navigateByUrl("dashboard/new-user");
  }

  getFullSellersName(orderConfirmation: Orderconfirmation) {
    return orderConfirmation.sellerFirstName + " " + orderConfirmation.sellerLastName;
  }

  disableButtonsAndEnableItAgain() {
    this.disable = true;
    setTimeout(() => {
      this.disable = false;
    }, 10000);
  }

  fulltextFilter(event: any) {
    // this.loadOrderConfirmations(event.value);
  }

  onEdit(id:any) {
    this.router.navigateByUrl(`user-management/edit/${id}`);
  }

  onDelete(id: any) {
    this.userService.delete(id)
        .then(res => {
            if (res.success) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Erfolg',
                    detail: 'Benutzer erfolgreich gelöscht.'
                });

                this.loadAllUsers();
            }
        })
        .catch(error => {
            console.error("Error deleting user:", error);
            // You can add further error handling logic here if needed
            this.messageService.add({
                severity: 'error',
                summary: 'Fehler',
                detail: 'Benutzer wurde nicht erfolgreich gelöscht.'
                
            });
        });
    }
}
