import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GlobalConstants } from '../shared/models/global.constants';
import {environment} from "../../environments/environment";
import jwt_decode from 'jwt-decode';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public cachedRequests: Array<HttpRequest<any>> = [];

    constructor(private router: Router, private jwtHelper: JwtHelperService, private http: HttpClient) {}

    public async authenticate(credentials: { email: string, password: string, otp: string, mode: string }): Promise<boolean> {
        try {
            const result: any = await this.http.post(environment.host + "auth/login", credentials).toPromise();
            if (result.access_token) {
                this.applyToken(result.access_token);
                return true;
            }
            return false;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    public applyToken(jwt: string): void {
        localStorage.setItem(GlobalConstants.JWT, jwt);
    }

    getJwtToken() {
        return localStorage.getItem(GlobalConstants.JWT);
    }

    getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch(Error) {
            return null;
        }
    }

    getUserIdFromToken() {
        // @ts-ignore
        const token = this.getDecodedAccessToken(this.getJwtToken());
        if (token) {
            return token.sub;
        } else {
            return null;
        }
    }

    getFirstNameFromToken() {
        // @ts-ignore
        const token = this.getDecodedAccessToken(this.getJwtToken());
        if (token) {
            return token.firstname;
        } else {
            return null;
        }
    }

    getLastNameFromToken() {
        // @ts-ignore
        const token = this.getDecodedAccessToken(this.getJwtToken());
        if (token) {
            return token.lastname;
        } else {
            return null;
        }
    }

    getEmailFromToken() {
        // @ts-ignore
        const token = this.getDecodedAccessToken(this.getJwtToken());
        if (token) {
            return token.username;
        } else {
            return null;
        }
    }

    private storeTokens({tokens}: { tokens: any }) {
        localStorage.setItem(GlobalConstants.JWT, tokens["result"].accessToken);
    }

    isLoggedIn() {
        return !!this.getJwtToken();
    }

    public async logout(): Promise<any> {
        try {
            const token = this.getJwtToken();
            if (token) {
                localStorage.removeItem(GlobalConstants.JWT);
            }
            return await this.router.navigate(['/login']);
        } catch (e) {
            localStorage.removeItem(GlobalConstants.JWT);
            return await this.router.navigate(['/login']);
        }
    }

    public collectFailedRequest(request: HttpRequest<any>): void {
        this.cachedRequests.push(request);
    }

    public isAuthenticated(): boolean {
        try {
            const token = this.getJwtToken();
            return !this.jwtHelper.isTokenExpired(token);
        } catch (e) {
            return false;
        }
    }
}
