import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse
} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {GlobalConstants} from "../shared/models/global.constants";

@Injectable()
export class ApiJwtInterceptor implements HttpInterceptor {

    constructor(public auth: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem(GlobalConstants.JWT);
        let newRequest: HttpRequest<any>;
        if (!token) {
            newRequest = request;
        } else {
            newRequest = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                    'Pragma': 'no-cache',
                    'Expires': '0'
                }
            });
        }

        return next.handle(newRequest).pipe(tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // do stuff with response if you want
                }
            }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        this.auth.collectFailedRequest(request);
                    }
                }
            })
        );
    }
}
