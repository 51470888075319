import { Component, OnInit } from '@angular/core';
import { handleError } from "../../shared/tools/error-helper";
import { ContractService } from "../../shared/service/contractService";
import { ConfirmationService, ConfirmEventType, MessageService } from "primeng/api";
import { Router } from "@angular/router";
import { Informationstemplate } from "../../shared/models/informationstemplate";
import { Table } from "primeng/table";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SettingsService } from "../../shared/service/settingsService";
import { VehicleEntities } from "../../shared/models/vehicleentities";
import { CreateInformationstemplate } from "../../shared/models/createinformationstemplate";

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
    public informationsData: any;
    public modifiedInformationsData: any;
    public filteredData: any;
    createInformationsTemplateEntitiy: CreateInformationstemplate = new CreateInformationstemplate();
    updateInformationsTemplateEntitiy: Informationstemplate = new Informationstemplate();
    loading: boolean = false;
    addInformationsTemplateVisible: boolean = false;
    editInformationsTemplateVisible: boolean = false;
    informationsTemplateForm: FormGroup;
    selectedType: string = '';
    templateTypes: any = ['Neuwagen', 'Gebrauchtwagen', 'Tageszulassung', 'TZ/VFW', 'Reisemobil neu', 'Reisemobil gebraucht', 'Oldtimer'];

    constructor(private contractService: ContractService,
        private messageService: MessageService,
        private router: Router,
        private settingsService: SettingsService,
        private confirmationService: ConfirmationService) {
        this.informationsTemplateForm = new FormGroup({
            templateType: new FormControl("", Validators.required),
            content: new FormControl("", Validators.required),
            description: new FormControl("", Validators.required),
            default: new FormControl(false),
            placeholder: new FormControl(false),
        });
    }

    ngOnInit(): void {
        this.loadTemplates();
    }

    async loadTemplates(): Promise<void> {
        try {
            this.loading = true;
            this.informationsData = await this.settingsService.listAllInformations();
            this.modifiedInformationsData = this.informationsData.filter((element: any) => element.modified === false);

        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Fehler',
                detail: 'Die Liste konnte nicht geladen werden.',
            });
            handleError(error, this.router);
        }
        this.loading = false;
    }

    // @ts-ignore
    applyFilterGlobal($event, stringVal, table: Table) {
        table.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
    }

    clear(table: Table) {
        table.clear();
        this.selectedType = '';
    }

    onTypeFilter() {
        // Implement your filtering logic based on selectedType
        // Example: Filter the table data based on selectedType
        if (this.selectedType) {
          this.modifiedInformationsData = this.modifiedInformationsData.filter((item:any) => item.templateType === this.selectedType);
        } else {
          // If no type selected, reset to original data
          this.loadTemplates(); // Refetch original data
        }
      }


    openInformations() {
        this.addInformationsTemplateVisible = true;
    }

    openEdit(templateInformation: any) {
        this.editInformationsTemplateVisible = true;
        this.patchEditValue(templateInformation);
    }

    deleteEntry(templateInformation: any) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete this entry?',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                try {
                    this.patchEditValue(templateInformation);
                    await this.settingsService.deleteInformation(templateInformation);
                    this.messageService.add({ severity: 'info', summary: 'Success', detail: 'The record was successfully deleted.' });

                    // Refresh data after deletion
                    await this.loadTemplates();

                } catch (e) {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to delete the record.' });
                }
            },
            reject: (type: any) => {
                switch (type) {
                    case ConfirmEventType.REJECT:
                        this.messageService.add({ severity: 'error', summary: 'Cancelled', detail: 'Deletion operation was cancelled.' });
                        break;
                    case ConfirmEventType.CANCEL:
                        this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Deletion operation was cancelled.' });
                        break;
                }
            }
        });
    }

    closeInformationsTemplateDialog() {
        this.informationsTemplateForm.reset();
        this.addInformationsTemplateVisible = false;
    }

    addInformationsTemplate() {
        this.applyValuesForCreate();
        this.loadTemplates();
        this.settingsService.createInformation(this.createInformationsTemplateEntitiy);
        this.addInformationsTemplateVisible = false;
        this.messageService.add({
            severity: 'success',
            summary: 'Erfolg',
            detail: 'Vorlage erfolgreich erstellt.'
        });
        this.informationsTemplateForm.reset();
        setTimeout(() => {
            this.loadTemplates();
        }, 2000);

    }

    updateInformationsTemplate() {
        this.applyValuesForUpdate();
        this.settingsService.updateInformation(this.updateInformationsTemplateEntitiy);
        this.editInformationsTemplateVisible = false;
        this.messageService.add({
            severity: 'success',
            summary: 'Erfolg',
            detail: 'Vorlage erfolgreich aktualisiert.'
        });
        this.informationsTemplateForm.reset();

        setTimeout(() => {
            this.loadTemplates();
        }, 2000);

    }

    closeEditInformationsTemplateDialog() {
        this.editInformationsTemplateVisible = false;
        this.informationsTemplateForm.reset();
    }

    protected readonly close = close;

    applyValuesForCreate() {
        const informationstemplateData = Object.assign(this.informationsTemplateForm.value);
        this.createInformationsTemplateEntitiy.templateType = informationstemplateData.templateType;
        this.createInformationsTemplateEntitiy.description = informationstemplateData.description;
        this.createInformationsTemplateEntitiy.content = informationstemplateData.content;
        this.createInformationsTemplateEntitiy.default = informationstemplateData.default;
        this.createInformationsTemplateEntitiy.placeholder = informationstemplateData.placeholder;
        this.createInformationsTemplateEntitiy.modified = false;
    }

    applyValuesForUpdate() {
        const informationstemplateData = Object.assign(this.informationsTemplateForm.value);
        this.updateInformationsTemplateEntitiy.templateType = informationstemplateData.templateType;
        this.updateInformationsTemplateEntitiy.id = informationstemplateData.id;
        this.updateInformationsTemplateEntitiy.description = informationstemplateData.description;
        this.updateInformationsTemplateEntitiy.content = informationstemplateData.content;
        this.updateInformationsTemplateEntitiy.default = informationstemplateData.default;
        this.updateInformationsTemplateEntitiy.placeholder = informationstemplateData.placeholder;
        this.createInformationsTemplateEntitiy.modified = false;
    }

    checkState(state: boolean) {
        return state;
    }

    patchEditValue(templateInformation: any) {

        this.informationsTemplateForm.addControl('id', new FormControl('', Validators.required));
        this.informationsTemplateForm.patchValue({
            id: templateInformation.id,
            description: templateInformation.description,
            content: templateInformation.content,
            default: templateInformation.default,
            placeholder: templateInformation.placeholder,
        });

        this.informationsTemplateForm.get('templateType')?.setValue(templateInformation.templateType);
    }

    removeInformationsFromTableAfterDelete(informationToDelete: any) {
        this.loadTemplates()
        this.informationsData.data.splice(this.informationsData.data.indexOf(informationToDelete), 1);
    }
}
