import {Injectable} from "@angular/core";
import {lastValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpBackend, HttpClient, HttpHeaders} from "@angular/common/http";
import {Contract} from "../models/contract";
import { MessageService } from "primeng/api";
import { Base64Mail } from "../models/base64Mail";
import { AuthService } from "src/app/auth/auth.service";

@Injectable({
    providedIn: 'root',
})
export class ContractService {
    private httpClient: HttpClient;
    Reportresponse: any;
    report: any;
    hideText: boolean = false;
    base64data: Base64Mail = new Base64Mail();

    sellerFirstName: any;
    sellerLastName: any;
    sellerEmailAddress: any;

    constructor(private handler: HttpBackend,
                private http: HttpClient,
                private messageService: MessageService,
                ) {

        this.httpClient = new HttpClient(handler);
    }

    listAllContracts(): Promise<any> {
        const result$ = this.http.get<any>(environment.host + 'contracts');
        return lastValueFrom(result$);
    }

    createCarEntry(contract: Contract): Promise<any> {
        const result$ = this.http.post<any>(environment.host + 'contracts', contract);
        return lastValueFrom(result$);
    }

    updateCarEntry(contract: Contract): Promise<any> {
        const result$ = this.http.put<any>(environment.host + 'contracts/' + contract.id, contract);
        return lastValueFrom(result$);
    }

    deleteContractAgreementsByContractId(contractId: string): void {
        const result$ = this.http.delete<any>(environment.host + 'contracts/agreements/' + contractId);
        console.log(result$);
    }

    getCarEntry(id: string): Promise<any> {
        const result$ = this.http.get<any>(environment.host + 'contracts/' + id);
        return lastValueFrom(result$);
    }

    async connectAuthentication(credentials: { username: string, password: string }): Promise<string> {
        try {
            const result: any = await this.http.post('https://connect-backend.platzer-wimmer.de/api/authentication/token', credentials).toPromise();
            if (result) {
                return result.accessToken;
            }
            return '';
        } catch (e) {
            console.error(e);
            return '';
        }
    }

    async connectCreateCall(data: any): Promise<any> {
        let username = environment.connectUsername;
        let password = environment.connectPassword;

        // Obtain authentication token
        const token = await this.connectAuthentication({username: username, password: password});

        // Create headers with the authentication token
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${token}`
        });

        try {
          // Send the HTTP request with the headers
          const result$ = this.httpClient.post("https://connect-backend.platzer-wimmer.de/api/Car", data, { headers });
          return await lastValueFrom(result$);
        } catch (e) {
          console.error(e);
          throw e;
        }
      }

    async createReport(report: any): Promise<any> {
        try {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': 'Basic ' + btoa("admin:GX2TXuL7ef6i5NS3b7SL"),
                    'Content-Type': 'application/json',
                }),
                responseType: 'blob' as 'json',  // Set responseType to 'blob'
            };
            const result$ = this.httpClient.post(environment.jsreportEndpoint + "/api/report", report, httpOptions);
            return await lastValueFrom(result$);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async sendOrderConfirmationEmail(attachment: Base64Mail): Promise<any> {
        const result$ = this.http.post<any>(environment.host + 'orderconfirmation/pdf', attachment);
        return lastValueFrom(result$);
    }

    async calculateTax(netvalue: number) {
        if (isNaN(netvalue)) {
            return 0;
        }
        return (netvalue * 0.19).toFixed(2);
    }

     // Define the formatAmount function
    async formatAmount(amount: any) {
        const numericValue = Number(amount);

        if (isNaN(numericValue)) {
          return "N/A";
        }

        return numericValue.toLocaleString('de-DE', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
      }

    async downloadReport(contractInformations: Contract,
                         reportType: string,
                         download: boolean,
                         send: boolean,
                         createConnectPlaceholder: boolean,
                         connect: boolean,
                         userDetail: any,
                         carType: string) {
        try {
            console.error(reportType);
            if (reportType === 'preContract') {
                this.report = {
                    data: {
                        contracttype: contractInformations.contracttype,
                        contractdate: contractInformations.contractdate,
                        vehicle: contractInformations.vehicle,
                        buyersalutation: contractInformations.buyersalutation,
                        buyerfirstname: contractInformations.buyerfirstname,
                        buyerlastname: contractInformations.buyerlastname,
                        buyerstreet: contractInformations.buyerstreet,
                        buyerzip: contractInformations.buyerzip,
                        buyercity: contractInformations.buyercity,
                        buyerphone: contractInformations.buyerphone,
                        buyeremail: contractInformations.buyermobile,
                        precontractinformations: contractInformations.precontractinformations,
                    },
                    template: {
                        name: 'VorvertraglicheInformationen',
                    }
                }
            } else if (reportType === 'contract') {
                console.error(carType);
                if(carType === 'used-car' || carType === 'used-camper' || carType === 'Oldtimer') {
                    this.report = {
                        data: {
                            vatReportable: contractInformations.vatReportable,
                            contracttype: contractInformations.contracttype,
                            buyersalutation: contractInformations.buyersalutation,
                            buyerfirstname: contractInformations.buyerfirstname,
                            buyerlastname: contractInformations.buyerlastname,
                            buyerstreet: contractInformations.buyerstreet,
                            buyerzip: contractInformations.buyerzip,
                            buyercity: contractInformations.buyercity,
                            buyerphone: contractInformations.buyerphone,
                            buyeremail: contractInformations.buyeremail,
                            brand: contractInformations.brand,
                            equipment: contractInformations.equipment,
                            engine: contractInformations.engine,
                            interior: contractInformations.interior,
                            tires: contractInformations.tires,
                            color: contractInformations.color,
                            vin: contractInformations.vin,
                            mileage: contractInformations.mileage,
                            firstregistration: contractInformations.firstregistration,
                            mainexaminationemissiontest: contractInformations.mainexaminationemissiontest,
                            previousowner: contractInformations.previousowner,
                            deliverydate: contractInformations.deliverydate,
                            vehiclepricenet: await this.formatAmount(contractInformations.vehiclepricenet),
                            vehiclepricegross: await this.formatAmount(contractInformations.vehiclepricegross),
                            additionalsnet: await this.formatAmount(contractInformations.additionalsnet),
                            discountnet: await this.formatAmount(contractInformations.discountnet),
                            housepricenet: await this.formatAmount(contractInformations.housepricenet),
                            endpricenet: await this.formatAmount(contractInformations.endpricenet),
                            endpricegross: await this.formatAmount(contractInformations.endpricegross),
                            endpricetax: await this.calculateTax(contractInformations.endpricenet),
                            transfergross: await this.formatAmount(contractInformations.transfergross),
                            transfernet: await this.formatAmount(contractInformations.transfernet),
                            discountgross: await this.formatAmount(contractInformations.discountgross),
                            housepricegross: await this.formatAmount(contractInformations.housepricegross),
                            paymenttype: contractInformations.paymenttype,
                            contractdate: contractInformations.contractdate,
                            vatradiobutton: contractInformations.vatReportable ? 'x': '',
                        },
                        template: {
                            name: 'vertrag-gw',
                        }
                    }
                } else {
                    this.report = {
                        data: {
                            vatReportable: contractInformations.vatReportable,
                            contracttype: contractInformations.contracttype,
                            vehicle: contractInformations.vehicle,
                            buyersalutation: contractInformations.buyersalutation,
                            buyerfirstname: contractInformations.buyerfirstname,
                            buyerlastname: contractInformations.buyerlastname,
                            buyerstreet: contractInformations.buyerstreet,
                            buyerzip: contractInformations.buyerzip,
                            buyercity: contractInformations.buyercity,
                            buyerphone: contractInformations.buyerphone,
                            buyermobile: contractInformations.buyermobile,
                            buyeremail: contractInformations.buyeremail,
                            brand: contractInformations.brand,
                            equipment: contractInformations.equipment,
                            engine: contractInformations.engine,
                            interior: contractInformations.interior,
                            tires: contractInformations.tires,
                            color: contractInformations.color,
                            vin: contractInformations.vin,
                            mileage: contractInformations.mileage,
                            firstregistration: contractInformations.firstregistration,
                            mainexaminationemissiontest: contractInformations.mainexaminationemissiontest,
                            previousowner: contractInformations.previousowner,
                            deliverydate: contractInformations.deliverydate,
                            vehiclepricenet: await this.formatAmount(contractInformations.vehiclepricenet),
                            vehiclepricegross: await this.formatAmount(contractInformations.vehiclepricegross),
                            additionalsnet: await this.formatAmount(contractInformations.additionalsnet),
                            discountnet: await this.formatAmount(contractInformations.discountnet),
                            housepricenet: await this.formatAmount(contractInformations.housepricenet),
                            endpricenet: await this.formatAmount(contractInformations.endpricenet),
                            endpricegross: await this.formatAmount(contractInformations.endpricegross),
                            endpricetax: await this.calculateTax(contractInformations.endpricenet),
                            transfergross: await this.formatAmount(contractInformations.transfergross),
                            transfernet: await this.formatAmount(contractInformations.transfernet),
                            discountgross: await this.formatAmount(contractInformations.discountgross),
                            housepricegross: await this.formatAmount(contractInformations.housepricegross),
                            paymenttype: contractInformations.paymenttype,
                            contractdate: contractInformations.contractdate,
                            vatradiobutton: contractInformations.vatReportable ? 'x': '',
                        },
                        template: {
                            name: 'Vertrag',
                        }
                    }
                }

            } else if (reportType === 'attachment') {
                this.report = {
                    data: {
                        contracttype: contractInformations.contracttype,
                        vehicle: contractInformations.vehicle,
                        buyersalutation: contractInformations.buyersalutation,
                        buyerfirstname: contractInformations.buyerfirstname,
                        buyerlastname: contractInformations.buyerlastname,
                        buyerstreet: contractInformations.buyerstreet,
                        buyerzip: contractInformations.buyerzip,
                        buyercity: contractInformations.buyercity,
                        buyerphone: contractInformations.buyerphone,
                        buyeremail: contractInformations.buyermobile,
                        contractdate: contractInformations.contractdate,
                        precontractinformations: contractInformations.precontractinformations,
                    },
                    template: {
                        name: 'Anhang',
                    }
                }
            } else if (reportType === 'orderConfirmation') {
                this.report = {
                    data: {
                        // userId: orderConfirmation.userId,
                        hideText: !!contractInformations.endpricenet,
                        salutation: contractInformations.buyersalutation,
                        firstName: contractInformations.buyerfirstname,
                        lastName: contractInformations.buyerlastname,
                        street: contractInformations.buyerstreet,
                        zip: contractInformations.buyerzip,
                        city: contractInformations.buyercity,
                        phone: contractInformations.buyerphone,
                        mobile: contractInformations.buyermobile,
                        email: contractInformations.buyeremail,
                        vehicle: contractInformations.vehicle,
                        priceNet: contractInformations.endpricenet,
                        priceGross: contractInformations.endpricegross,
                        tax: await this.calculateTax(contractInformations.endpricenet),
                        orderConfirmationDate: contractInformations.contractdate,
                        sellerFirstName: userDetail.sellerFirstName,
                        sellerLastName: userDetail.sellerLastName,
                        sellerEmailAddress: userDetail.sellerEmailAddress,
                    },
                    template: {
                        name: 'Auftragsbestaetigung',
                    }
                }
            }

            // Call createReport and wait for the response
            this.Reportresponse = await this.createReport(this.report);

            setTimeout(() => {
                if (this.Reportresponse instanceof Blob) {
                  const blobUrl = URL.createObjectURL(this.Reportresponse);
                  const link = document.createElement('a');

                  if (download) {
                    if (reportType === 'orderConfirmation') {
                      link.href = blobUrl;
                      link.download = `Vertrag_${this.report.data.firstName}_${this.report.data.lastName}.pdf`;
                    } else {
                      link.href = blobUrl;
                      link.download = `Vertrag_${this.report.data.buyerfirstname}_${this.report.data.buyerlastname}.pdf`;
                    }

                    link.click();
                  } else {
                    console.error('Unexpected response type:', this.Reportresponse);
                  }
                }
              }, 2000);


            if (this.Reportresponse && createConnectPlaceholder) {
                if(connect) {
                    const carData = await this.connectCreateCall(this.report.data);
                    console.warn(carData);
                }
            }
            this.messageService.add({
                severity: 'success',
                summary: 'Erfolg',
                detail: 'Der Auftrag wurde erfolgreich ausgeführt.',
            });
        } catch (e) {
            this.messageService.add({
                severity: 'error',
                summary: 'Fehler',
                detail: 'Es ist ein Fehler aufgetreten: ' + e,
            });
            console.error(e);
        }
    }

    async convertToBase64(url: string, report: any): Promise<Base64Mail> {
        this.http.get(url, { responseType: "blob" }).subscribe(blob => {
            const reader = new FileReader();
            const binaryString = reader.readAsDataURL(blob);
            reader.onload = (event: any) => {
                this.base64data.base64 = event.target.result.replace("data:application/pdf;base64,", "");
                this.base64data.subject = report.vehicle;
                this.base64data.receiverEmail = report.email;
                this.base64data.sellerEmail = report.sellerEmailAddress;
                return this.base64data;
            };

            reader.onerror = (event: any) => {
                console.log("File could not be read: " + event.target.error.code);
            };
        });
        return this.base64data;
    }


}
