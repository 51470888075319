import {NgModule} from '@angular/core';
import {ButtonModule} from 'primeng/button';
import {SidebarModule} from 'primeng/sidebar';
import {SidebarComponent} from './sidebar/sidebar.component';
import {MenuModule} from 'primeng/menu';
import {PanelMenuModule} from 'primeng/panelmenu';
import {CommonModule} from '@angular/common';
import {DialogModule} from 'primeng/dialog';
import {SelectButtonModule} from 'primeng/selectbutton';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SplitterModule} from 'primeng/splitter';
import {GalleriaModule} from 'primeng/galleria';
import {TabViewModule} from 'primeng/tabview';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputTextModule} from 'primeng/inputtext';
import {CalendarModule} from 'primeng/calendar';
import {InputMaskModule} from 'primeng/inputmask';
import {FileUploadModule} from 'primeng/fileupload';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ChipModule} from 'primeng/chip';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ToastModule} from 'primeng/toast';
import {DataViewModule} from 'primeng/dataview';
import {PaginatorModule} from 'primeng/paginator';
import {RatingModule} from 'primeng/rating';
import {CheckboxModule} from 'primeng/checkbox';
import {ImageModule} from 'primeng/image';
import {SkeletonModule} from 'primeng/skeleton';
import {TableModule} from 'primeng/table';
import {CardModule} from 'primeng/card';
import {BadgeModule} from 'primeng/badge';
import {FooterComponent} from './footer/footer.component';
import {MessageService} from "primeng/api";
import {StyleClassModule} from "primeng/styleclass";
import {RippleModule} from "primeng/ripple";
import { AgreementsComponent } from './agreements/agreements.component';
import {EditorModule} from "primeng/editor";

@NgModule({
    declarations: [SidebarComponent, FooterComponent, AgreementsComponent],
    imports: [
        SidebarModule,
        PanelMenuModule,
        ButtonModule,
        MenuModule,
        CommonModule,
        DialogModule,
        SelectButtonModule,
        FormsModule,
        ReactiveFormsModule,
        SplitterModule,
        GalleriaModule,
        TabViewModule,
        InputNumberModule,
        InputTextModule,
        CalendarModule,
        InputMaskModule,
        FileUploadModule,
        ConfirmDialogModule,
        ChipModule,
        RadioButtonModule,
        ToastModule,
        DataViewModule,
        PaginatorModule,
        RatingModule,
        CheckboxModule,
        ImageModule,
        SkeletonModule,
        TableModule,
        CardModule,
        BadgeModule,
        StyleClassModule,
        RippleModule,
        EditorModule,
    ],
    exports: [SidebarComponent, FooterComponent, AgreementsComponent],
    providers: [MessageService],
})
export class SharedModule {
}
