import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss']
})
export class AgreementsComponent implements OnInit {

  @Input()
  public agreements: any[] = [];

  @Input()
  public contractIsCreated: boolean = false;
  isAgreementSelectionVisible: boolean = false;
  loading: boolean = false;
  selectedAgreements: any;
  preContractInformations: any[] = [];
  isAgreementVisible: boolean = false;
  text: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  openSelectionPopup() {

  }

  showAgreementPopup() {

  }

  checkState(placeholder: string): boolean {
    return false;
  }

  addPreContractInformationsToTable() {
    
  }

  closeAgreementSelectionPopup() {
    this.isAgreementSelectionVisible = false;
  }

  onTextChange($event: any) {
    this.text = $event;
  }

  createAgreementToTable() {

  }

  closeAgreementPopup() {
    this.isAgreementVisible = false;
  }
}
