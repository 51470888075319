<app-sidebar>
    <p-toast></p-toast>
    <form [formGroup]="userForm">
        <div class="custom-contract-card">
            <p-card>
                <h2 class="mt-0">{{formTitle}}</h2>
                <div class="grid mt-5">

                    <div class="col-6 mt-4 p-fluid">
                        <span class="p-float-label">
                            <input formControlName="firstName" pInputText type="text" />
                            <label>Vorname</label>
                        </span>
                    </div>
                    <div class="col-6 mt-4 p-fluid">
                        <span class="p-float-label">
                            <input formControlName="lastName" pInputText type="text" />
                            <label>Nachname</label>
                        </span>
                    </div>
                    <div class="col-6 mt-4 p-fluid">
                        <span class="p-float-label">
                            <input formControlName="email" pInputText type="text" />
                            <label>E-Mail</label>
                        </span>
                    </div>
                    <div class="col-6 mt-4 p-fluid" *ngIf="!id">
                        <span class="p-float-label">
                            <input formControlName="password" pInputText type="text" />
                            <label>Passwort</label>
                        </span>
                    </div>
                   
                    <div class="col-6 mt-4 p-fluid">
                        <p-checkbox [binary]="true" label="Administrator"
                        [(ngModel)]="isAdmin" [ngModelOptions]="{standalone: true}" (ngModelChange)="getAdminValue($event)"></p-checkbox>
                    </div>

                </div>
                <p class="mt-4"></p>
                <p-toast></p-toast>
                <ng-template pTemplate="footer">
                    <div class="grid">
                        <div class="col-10"></div>
                        <div class="col-2">
                            <button *ngIf="!id" (click)="save()" class="custom-save-button"
                                icon="pi pi-save" label="Speichern" pButton type="submit"></button>

                            <button *ngIf="id" (click)="update()" [disabled]="userForm.invalid" class="custom-save-button"
                                icon="pi pi-save" label="aktualisieren" pButton type="submit"></button>
                        </div>
                    </div>
                </ng-template>
            </p-card>
        </div>
    </form>
</app-sidebar>
