import {Injectable} from "@angular/core";
import {lastValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Contract} from "../models/contract";

@Injectable({
    providedIn: 'root',
})
export class UserService {

    constructor(private http: HttpClient) {
    }

    listAllUsers(): Promise<any> {
        const result$ = this.http.get<any>(environment.host + 'users');
        return lastValueFrom(result$);
    }

    createNewUser(formValues: any): Promise<any> {
        const result$ = this.http.post<any>(environment.host + 'users/register', formValues);
        return lastValueFrom(result$);
    }

    getUser(id: any): Promise<any> {
        const result$ = this.http.get<any>(environment.host + 'users/' + id);
        return lastValueFrom(result$);
    }

     put(slug: any, postData: any) {
        const result$ = this.http.put<any>(environment.host + 'users/' + slug, postData);
        return lastValueFrom(result$);
    }

    delete(slug: any) {
        const result$ = this.http.delete<any>(environment.host + 'users/' + slug);
        return lastValueFrom(result$);
    }
}
