// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    //host: "http://localhost:3000/api/v1/",
    host: "https://vertrag-backend.test.app-simple.de/api/v1/",
    carboneApiKey: "eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI2MDYwODk3ODkxMzE0OTk2MTEiLCJhdWQiOiJjYXJib25lIiwiZXhwIjoyMzQzOTY2OTY0LCJkYXRhIjp7ImlkQWNjb3VudCI6IjYwNjA4OTc4OTEzMTQ5OTYxMSJ9fQ.AeoquUXgGdydqzztUFCKswUl2NQTw0giT82DMBXucxg7TIKIe17uS0kVNEZLKPLjPdpAcJQpUlvsIj7aVgFqd61qAP9MUKQTisj_gr92gBEC0oK3sH-93LOZPR4fTVcv4g5Chea4k9VORk29fuGpa6f6vQKnIWJNMxQwJmaPx4OesTXc",
    jsreportEndpoint: 'https://report.app-simple.de',
    connectUsername: 'mw@broadcastx.de',
    connectPassword: 'W)FnxpTEB?12VT23CL1rz3sA',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
