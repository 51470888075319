<div class='footer flex-row'>
    <div [ngClass]='sidebarState'>
        <div class='grid'>
            <div class='col'>
					<span><a href='https://app-simple.de' target='_blank' style="text-decoration: none;">
                        app & simple UG (hb.)</a> | All Rights Reserved 2024</span>
            </div>
        </div>
    </div>
</div>
