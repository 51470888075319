import {Component, HostListener, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {PreContractInformation} from "../../shared/models/preContractInformation";
import {RenderTemplate} from "../../shared/models/renderTemplate";
import {RenderPdfService} from "../../shared/service/renderPdfService";
import {ContractService} from "../../shared/service/contractService";
import {MessageService} from "primeng/api";
import {ActivatedRoute, Router} from "@angular/router";
import {handleError} from "../../shared/tools/error-helper";
import {FormType} from "../../shared/models/formType";
import {Contract} from "../../shared/models/contract";
import { SettingsService } from 'src/app/shared/service/settingsService';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
    selector: 'app-day-admission',
    templateUrl: './day-admission.component.html',
    styleUrls: ['./day-admission.component.scss']
})
export class DayAdmissionComponent implements OnInit {
    contractForm: FormGroup;
    pciChangeForm: FormGroup;
    contractId: string = '';
    selectedValue: string = 'val2';
    type: string = '';
    templateinformationsnewcar: any;
    newContractInformations: any[] = [];
    dataDayAdmission: any[] = [];
    preContractInformations: any[] = [];
    loading: boolean = false;
    activeState: boolean[] = [true, false, false];
    isAgreementVisible: boolean = false;
    preContractInformationToChange: PreContractInformation = new PreContractInformation();
    contractDataPaymentType: any = [];
    contractDataType: any = [];
    contractInformationsResponse: any;
    contractIsCreated: boolean = false;
    isConnect: boolean = false;
    isAgreementSelectionVisible: boolean = false;
    isDetailView: boolean = false;
    showPciEditField: boolean = false;
    vatReportable: boolean = true;
    text: string = '';
    pciToEdit: string = '';
    preContractInfoId!: any;
    selectedAgreements: any;
    styleDownloadFileCard = {background: '#f2f4f7', marginBottom: '2em'};
    contractInformations: Contract = new Contract();
    taxselect!: FormGroup;
    netgrossselect!: FormGroup;

    sellerFirstName: any;
    sellerLastName: any;
    sellerEmailAddress: any;
    userDetail: any;

    loadingPreContract: boolean = false;
    loadingContract: boolean = false;
    loadingAttachment: boolean = false;
    loadingOrderConfirmation: boolean = false;

    stateOptions: any[] = [
        { label: 'Netto', value: 'net' },
        { label: 'Brutto', value: 'gross' }
    ];

    taxOptions: any[] = [
        { label: 'MwSt. ausweisbar', value: 'tax' },
        { label: 'Nicht ausweisbar', value: 'no-tax' }
      ];

    constructor(private renderPdfService: RenderPdfService,
                private contractService: ContractService,
                private messageService: MessageService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private settingsService: SettingsService,
                private authService: AuthService) {

        this.contractDataPaymentType = [
            {name: "Bar", value: "Bar"},
            {name: "Finanzierung", value: "Finanzierung"},
            {name: "Leasing", value: "Leasing"},
            {name: "Miete", value: "Miete"},
        ];

        this.contractDataType = [
            {name: "Privat", code: "pr", value: "privat"},
            {name: "Gewerblich", code: "ge", value: "gewerblich"},
        ];

        this.pciChangeForm = new FormGroup({
            pciTableChange: new FormControl(""),
        });

        this.taxselect = new FormGroup({
            value: new FormControl(""),
        });

        this.netgrossselect = new FormGroup({
            value: new FormControl(""),
        });


        this.contractForm = new FormGroup({
            id: new FormControl(""),
            contracttype: new FormControl("", Validators.required),
            vehicle: new FormControl("", Validators.required),
            buyersalutation: new FormControl("", Validators.required),
            buyerfirstname: new FormControl("", Validators.required),
            buyerlastname: new FormControl("", Validators.required),
            buyerstreet: new FormControl("", Validators.required),
            buyerzip: new FormControl("", Validators.required),
            buyercity: new FormControl("", Validators.required),
            buyerphone: new FormControl(""),
            buyermobile: new FormControl(""),
            buyeremail: new FormControl(""),
            brand: new FormControl(""),
            equipment: new FormControl(""),
            engine: new FormControl(""),
            color: new FormControl(""),
            interior: new FormControl(""),
            tires: new FormControl(""),
            contractdate: new FormControl("", Validators.required),
            deliverydate: new FormControl(""),
            vehiclepricenet: new FormControl(Number),
            vehiclepricegross: new FormControl(Number),
            additionalsnet: new FormControl(Number),
            additionalsgross: new FormControl(Number),
            listpricenet: new FormControl(Number),
            listpricegross: new FormControl(Number),
            discountnet: new FormControl(Number),
            discountgross: new FormControl(Number),
            housepricenet: new FormControl(Number),
            housepricegross: new FormControl(Number),
            accessoiresnet: new FormControl(Number),
            accessoiresgross: new FormControl(Number),
            transfernet: new FormControl(Number),
            transfergross: new FormControl(Number),
            endpricenet: new FormControl(Number),
            endpricegross: new FormControl(Number),
            paymenttype: new FormControl(""),
            precontractinformations: new FormControl(""),
            formtype: new FormControl(""),
            pciTableChange: new FormControl(),
            vatReportable: new FormControl(false),

            vin: new FormControl("", Validators.required),
            mileage: new FormControl(""),
            firstregistration: new FormControl(""),
            mainexaminationemissiontest: new FormControl(""),
            previousowner: new FormControl(""),
        });

        this.sellerFirstName = this.authService.getFirstNameFromToken();
        this.sellerLastName = this.authService.getLastNameFromToken();
        this.sellerEmailAddress = this.authService.getEmailFromToken();

            this.userDetail = {
            sellerFirstName: this.sellerFirstName,
            sellerLastName: this.sellerLastName,
            sellerEmailAddress: this.sellerEmailAddress,
        }
    }

    async ngOnInit(): Promise<void> {
        this.activatedRoute.params.subscribe((params) => {
            this.contractId = params['id'];
            this.type = params['type'];
        });

        if (this.contractId) {
            await this.loadContractById(this.contractId);
            this.contractForm.get('id')?.setValue(this.contractId);
        } else {
            this.netgrossselect = new FormGroup({
                value: new FormControl('net')
            });
            this.taxselect = new FormGroup({
                value: new FormControl('tax')
              });
            this.enableNetValues();

            await this.loadNewCarTemplates();
            this.checkDefaultEntries();
        }
    }

    @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler() {
        if (this.isAgreementVisible) {
            this.closeAgreementPopup();
        }
    }

    async loadNewCarTemplates(): Promise<void> {
        try {
            this.loading = true;
            this.templateinformationsnewcar = await this.settingsService.listAllInformations();
            this.getTemplatesData(this.templateinformationsnewcar.data)
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Fehler',
                detail: 'Die Liste konnte nicht geladen werden.',
            });
            handleError(error, this.router);
        }
        this.loading = false;
    }

    onChange(e: any): void {
        if(e == 'val1') {
            this.vatReportable = true;
        } else {
            this.vatReportable = false;
            this.taxselect.get('value')?.setValue('no-tax');
        }
    }

    getTemplatesData(templateinformations: any) {
        this.dataDayAdmission = [...templateinformations];
        return this.dataDayAdmission;
    }

    async loadContractById(contractId: string) {
        try {
            if(this.type === "view") {
                this.contractForm.disable();
                this.contractIsCreated = true;
                this.isDetailView = true;
            } else {
                this.contractForm.enable();
                this.contractIsCreated = false;
                this.isDetailView = false;
            }
            this.contractInformations = await this.contractService.getCarEntry(contractId);
            this.patchValues(this.contractInformations);
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Fehler',
                detail: 'Der Vertrag konnte nicht geladen werden.',
            });
            handleError(error, this.router);
        }
    }

    addPreContractInformationsToTable() {
        this.closeAgreementSelectionPopup();

        const allItemsFound = this.selectedAgreements.map((agreement:any) => {
            return this.preContractInformations.some(el => el?.id === agreement?.id);
        });

        if (allItemsFound.every((found:any) => found)) {
            this.newContractInformations.push(...this.selectedAgreements);
        } else {
            this.selectedAgreements = [];
        }
    }

    checkDefaultEntries() {
        // Ensure proper initialization
        this.dataDayAdmission = this.dataDayAdmission || [];
        this.preContractInformations = this.preContractInformations || [];

        for (let i = 0; i < this.dataDayAdmission.length; i++) {
            // Check if the current element has the expected properties
            if (this.dataDayAdmission[i].templateType && this.dataDayAdmission[i].templateType === 'Tageszulassung') {
                this.preContractInformations.push(this.dataDayAdmission[i]);

                if(this.dataDayAdmission[i].default) {
                    this.newContractInformations.push(this.dataDayAdmission[i]);
                }
            }
        }
    }

    changeNetGross(event: any) {
        if (event.value === 'net') {
            this.vatReportable = true;
            this.netgrossselect.get('value')?.setValue('net');
            this.taxselect.get('value')?.setValue('tax');
            // this.enableNetValues();
            // this.disableGrossValues();
        } else {
            this.vatReportable = false;
            this.netgrossselect.get('value')?.setValue('gross');
            this.taxselect.get('value')?.setValue('no-tax');
            // this.enableGrossValues();
            // this.disableNetValues();
        }
    }

    enableNetValues() {
        this.resetValuesOnChange();
        this.contractForm.controls['vehiclepricenet'].enable();
        this.contractForm.controls['listpricenet'].enable();
        this.contractForm.controls['additionalsnet'].enable();
        this.contractForm.controls['discountnet'].enable();
        this.contractForm.controls['housepricenet'].enable();
        this.contractForm.controls['accessoiresnet'].enable();
        this.contractForm.controls['transfernet'].enable();
        this.contractForm.controls['endpricenet'].enable();
    }

    disableNetValues() {
        this.resetValuesOnChange();
        this.contractForm.controls['vehiclepricenet'].disable();
        this.contractForm.controls['listpricenet'].disable();
        this.contractForm.controls['additionalsnet'].disable();
        this.contractForm.controls['discountnet'].disable();
        this.contractForm.controls['housepricenet'].disable();
        this.contractForm.controls['accessoiresnet'].disable();
        this.contractForm.controls['transfernet'].disable();
        this.contractForm.controls['endpricenet'].disable();
    }

    enableGrossValues() {
        this.resetValuesOnChange();
        this.contractForm.controls['vehiclepricegross'].enable();
        this.contractForm.controls['listpricegross'].enable();
        this.contractForm.controls['additionalsgross'].enable();
        this.contractForm.controls['discountgross'].enable();
        this.contractForm.controls['housepricegross'].enable();
        this.contractForm.controls['accessoiresgross'].enable();
        this.contractForm.controls['transfergross'].enable();
        this.contractForm.controls['endpricegross'].enable();
    }

    disableGrossValues() {
        this.resetValuesOnChange();
        this.contractForm.controls['vehiclepricegross'].disable();
        this.contractForm.controls['listpricegross'].disable();
        this.contractForm.controls['additionalsgross'].disable();
        this.contractForm.controls['discountgross'].disable();
        this.contractForm.controls['housepricegross'].disable();
        this.contractForm.controls['accessoiresgross'].disable();
        this.contractForm.controls['transfergross'].disable();
        this.contractForm.controls['endpricegross'].disable();
    }

    resetValuesOnChange() {
        this.contractForm.controls['vehiclepricegross'].reset();
        this.contractForm.controls['listpricegross'].reset();
        this.contractForm.controls['additionalsgross'].reset();
        this.contractForm.controls['discountgross'].reset();
        this.contractForm.controls['housepricegross'].reset();
        this.contractForm.controls['accessoiresgross'].reset();
        this.contractForm.controls['transfergross'].reset();
        this.contractForm.controls['endpricegross'].reset();
        this.contractForm.controls['vehiclepricenet'].reset();
        this.contractForm.controls['listpricenet'].reset();
        this.contractForm.controls['additionalsnet'].reset();
        this.contractForm.controls['discountnet'].reset();
        this.contractForm.controls['housepricenet'].reset();
        this.contractForm.controls['accessoiresnet'].reset();
        this.contractForm.controls['transfernet'].reset();
        this.contractForm.controls['endpricenet'].reset();
    }

    toggle(index: number) {
        this.activeState[index] = !this.activeState[index];
    }

    showAgreementPopup() {
        this.isAgreementVisible = true;
    }

    closeAgreementPopup() {
        this.isAgreementVisible = false;
    }

    removePrecontractInformation(preContractInformation: any) {
        this.newContractInformations.splice(this.newContractInformations.indexOf(preContractInformation), 1);
    }

    // nimmt den Wert aus "Fahrzeugpreis netto" und berechnet damit "Fahrzeugpreis brutto"
    vehiclePriceNetChanged() {
        const input = this.contractForm.get("vehiclepricenet")?.value;
        if (input) {
            this.contractForm.patchValue({
                vehiclepricegross: this.netToGrossCalculation(input).toFixed(2),
            });
        } else {
            this.contractForm.patchValue({
                vehiclepricegross: 0,
            });
        }
        // Berechnet "Listenpreis netto"
        if (
            this.contractForm.get("vehiclepricenet")?.value &&
            this.contractForm.get("additionalsnet")?.value
        ) {
            this.listPriceNetCalculation();
        } else {
            this.contractForm.patchValue({
                listpricenet: 0,
            });
        }
    }

    // nimmt den Wert aus "Fahrzeugpreis brutto" und berechnet damit "Fahrzeugpreis netto"
    vehiclePriceGrossChanged() {
        const input = this.contractForm.get("vehiclepricegross")?.value;
        if (input) {
            this.contractForm.patchValue({
                vehiclepricenet: this.grossToNetCalculation(input).toFixed(2),
            });
        } else {
            this.contractForm.patchValue({
                vehiclepricenet: 0,
            });
        }
        // Berechnet "Listenpreis brutto"
        if (
            this.contractForm.get("vehiclepricegross")?.value &&
            this.contractForm.get("additionalsgross")?.value
        ) {
            this.listPriceGrossCalculation();
        } else {
            this.contractForm.patchValue({
                listpricegross: 0,
            });
        }
    }

    // nimmt den Wert aus "Sonderausstattung netto" und berechnet damit "Sonderausstattung brutto"
    additionalsNetChanged() {
        const input = this.contractForm.get("additionalsnet")?.value;
        if (input) {
            this.contractForm.patchValue({
                additionalsgross: this.netToGrossCalculation(input).toFixed(2),
            });
        } else {
            this.contractForm.patchValue({
                additionalsgross: 0,
            });
        }
        //Brechnet "Listenpreis netto"
        if (
            this.contractForm.get("vehiclepricenet")?.value &&
            this.contractForm.get("additionalsnet")?.value
        ) {
            this.listPriceNetCalculation();
        } else {
            this.contractForm.patchValue({
                listpricenet: 0,
            });
        }
    }

    onTextChange(event: any) {
        this.text = event;
        this.contractForm.get("precontractinformations")?.setValue(this.text);
    }

    getConnectValue(ev: boolean) {
        this.isConnect = ev;
    }

    editPrecontractInformation(preContractInformation: any) {
        this.showPciEditPopup();
        this.pciToEdit = preContractInformation.content;
        this.contractForm.controls['pciTableChange'].setValue(this.pciToEdit);
        //TODO: set value to textfield
        // this.pciChangeForm.patchValue({
        //     pciTableChange: this.pciToEdit,
        // })

        this.precontractInformation(preContractInformation);
    }

    changePrecontractInformation() {
        //TODO: change values in table
        let data = this.preContractInformations.filter(item => item.id === this.preContractInfoId);
        data[0].content = this.contractForm.get("pciTableChange")?.value;
        this.closePciEditPopup();
    }

    public precontractInformation(preContractInfoObject: any) {
        this.preContractInfoId = preContractInfoObject.id;
    }

    // nimmt den Wert aus "Sonderausstattung brutto" und berechnet damit "Sonderausstattung netto"
    additionalsGrossChanged() {
        const input = this.contractForm.get("additionalsgross")?.value;
        if (input) {
            this.contractForm.patchValue({
                additionalsnet: this.grossToNetCalculation(input).toFixed(2),
            });
        } else {
            this.contractForm.patchValue({
                additionalsnet: 0,
            });
        }
        // Berechnet "Listenpreis brutto"
        if (
            this.contractForm.get("vehiclepricegross")?.value &&
            this.contractForm.get("additionalsgross")?.value
        ) {
            this.listPriceGrossCalculation();
        } else {
            this.contractForm.patchValue({
                listpricegross: 0,
            });
        }
    }

    // nimmt den Wert aus "Nachlass netto" und berechnet damit "Nachlass brutto"
    discountNetChanged() {
        const input = this.contractForm.get("discountnet")?.value;
        if (input) {
            this.contractForm.patchValue({
                discountgross: this.netToGrossCalculation(input).toFixed(2),
            });
        } else {
            this.contractForm.patchValue({
                discountgross: 0,
            });
        }
        // Berechnet "Hauspreis netto"
        if (
            this.contractForm.get("listpricenet")?.value &&
            this.contractForm.get("discountnet")?.value
        ) {
            this.housePriceNetCalculation();
        } else {
            this.contractForm.patchValue({
                housepricenet: 0,
            });
        }
    }

    // nimmt den Wert aus "Nachlass brutto" und berechnet damit "Nachlass netto"
    discountGrossChanged() {
        const input = this.contractForm.get("discountgross")?.value;
        if (input) {
            this.contractForm.patchValue({
                discountnet: this.grossToNetCalculation(input).toFixed(2),
            });
        } else {
            this.contractForm.patchValue({
                discountnet: 0,
            });
        }
        // Berechnet "Hauspreis brutto"
        if (
            this.contractForm.get("listpricegross")?.value &&
            this.contractForm.get("discountgross")?.value
        ) {
            this.housePriceGrossCalculation();
        } else {
            this.contractForm.patchValue({
                housepricegross: 0,
            });
        }
    }

     /** create new agreement open editor popup */
     createAgreementToTable() {
        this.closeAgreementPopup();
        // this.preContractInformations.push(
        //     this.contractForm.get("precontractinformations")?.value
        // );
        this.newContractInformations.push({
            id: '',
            content: this.text,
            date_created: null,
            date_updated: new Date(),
            default: true,
            description: null,
            placeholder: false,
            user_created: new Date(),
            user_updated: null
        });
        this.contractForm.get("precontractinformations")?.setValue("");
    }

    // nimmt den Wert aus "Zubehör netto" und berechnet damit "Zubehör brutto"
    accessoiresNetChanged() {
        const input = this.contractForm.get("accessoiresnet")?.value;
        if (input) {
            this.contractForm.patchValue({
                accessoiresgross: this.netToGrossCalculation(input).toFixed(2),
            });
        } else {
            this.contractForm.patchValue({
                accessoiresgross: 0,
            });
        }
        // Berechnet Endpreis netto
        if (
            this.contractForm.get("housepricenet")?.value &&
            this.contractForm.get("accessoiresnet")?.value &&
            this.contractForm.get("transfernet")?.value
        ) {
            this.endPriceNetCalculation();
        } else {
            this.contractForm.patchValue({
                endpricenet: 0,
            });
        }
    }

    // nimmt den Wert aus "Zubehör brutto" und berechnet damit "Zubehör netto"
    accessoiresGrossChanged() {
        const input = this.contractForm.get("accessoiresgross")?.value;
        if (input) {
            this.contractForm.patchValue({
                accessoiresnet: this.grossToNetCalculation(input).toFixed(2),
            });
        } else {
            this.contractForm.patchValue({
                accessoiresnet: 0,
            });
        }
        // Berechnet "Endpreis brutto"
        if (
            this.contractForm.get("housepricegross")?.value &&
            this.contractForm.get("accessoiresgross")?.value &&
            this.contractForm.get("transfergross")?.value
        ) {
            this.endPriceGrossCalculation();
        } else {
            this.contractForm.patchValue({
                endpricegross: 0,
            });
        }
    }

    // nimmt den Wert aus "Fracht brutto" und berechnet damit "Fracht netto"
    transferGrossChanged() {
        const input = this.contractForm.get("transfergross")?.value;
        if (input) {
            this.contractForm.patchValue({
                transfernet: this.grossToNetCalculation(input).toFixed(2),
            });
        } else {
            this.contractForm.patchValue({
                transfernet: 0,
            });
        }
        // Berechnet "Endpreis brutto"
        if (
            this.contractForm.get("housepricegross")?.value &&
            this.contractForm.get("accessoiresgross")?.value &&
            this.contractForm.get("transfergross")?.value
        ) {
            this.endPriceGrossCalculation();
        } else {
            this.contractForm.patchValue({
                endpricegross: 0,
            });
        }
    }

    // nimmt den Wert aus "Fracht netto" und berechnet damit "Fracht brutto"
    transferNetChanged() {
        const input = this.contractForm.get("transfernet")?.value;
        if (input) {
            this.contractForm.patchValue({
                transfergross: this.netToGrossCalculation(input).toFixed(2),
            });
        } else {
            this.contractForm.patchValue({
                transfergross: 0,
            });
        }
        // Berechnet "Endpreis netto"
        if (
            this.contractForm.get("housepricenet")?.value &&
            this.contractForm.get("accessoiresnet")?.value &&
            this.contractForm.get("transfernet")?.value
        ) {
            this.endPriceNetCalculation();
        } else {
            this.contractForm.patchValue({
                endpricenet: 0,
            });
        }
    }

    // nimmt den Wert aus "Listenpreis netto" und berechnet damit "Listenpreis brutto"
    listPriceNetChanged() {
        const input = this.contractForm.get("listpricenet")?.value;
        if (input) {
            this.contractForm.patchValue({
                listpricegross: this.netToGrossCalculation(input).toFixed(2),
            });
        } else {
            this.contractForm.patchValue({
                listpricegross: 0,
            });
        }
    }

    // nimmt den Wert aus "Listenpreis brutto" und berechnet damit "Listenpreis netto"
    listPriceGrossChanged() {
        const input = this.contractForm.get("listpricegross")?.value;
        if (input) {
            this.contractForm.patchValue({
                listpricenet: this.grossToNetCalculation(input).toFixed(2),
            });
        } else {
            this.contractForm.patchValue({
                listpricenet: 0,
            });
        }
    }

    // nimmt den Wert aus "Hauspreis netto" und berechnet damit "Hauspreis brutto"
    housePriceNetChanged() {
        const input = this.contractForm.get("housepricenet")?.value;
        if (input) {
            this.contractForm.patchValue({
                housepricegross: this.netToGrossCalculation(input).toFixed(2),
            });
        } else {
            this.contractForm.patchValue({
                housepricegross: 0,
            });
        }
    }

    // nimmt den Wert aus "Hauspreis brutto" und berechnet damit "Hauspreis netto"
    housePriceGrossChanged() {
        const input = this.contractForm.get("housepricegross")?.value;
        if (input) {
            this.contractForm.patchValue({
                housepricenet: this.grossToNetCalculation(input).toFixed(2),
            });
        } else {
            this.contractForm.patchValue({
                housepricenet: 0,
            });
        }
    }

    // nimmt den Wert aus "Endpreis netto" und berechnet damit "Endpreis brutto"
    endPriceNetChanged() {
        const input = this.contractForm.get("endpricenet")?.value;
        if (input) {
            this.contractForm.patchValue({
                endpricegross: this.netToGrossCalculation(input).toFixed(2),
            });
        } else {
            this.contractForm.patchValue({
                endpricegross: 0,
            });
        }
    }

    // nimmt den Wert aus "Endpreis brutto" und berechnet damit "Endpreis netto"
    endPriceGrossChanged() {
        const input = this.contractForm.get("endpricegross")?.value;
        if (input) {
            this.contractForm.patchValue({
                endpricenet: this.grossToNetCalculation(input).toFixed(2),
            });
        } else {
            this.contractForm.patchValue({
                endpricenet: 0,
            });
        }
    }

    // nimmt den Wert aus "Fahrzeugpreis netto" und "Sonderausstattung netto" um "Listenpreis netto" zu berechnen
    listPriceNetCalculation() {
        const vehiclePriceNet = this.contractForm.get("vehiclepricenet")?.value;
        const additionalsNet = this.contractForm.get("additionalsnet")?.value;
        this.contractForm.patchValue({
            listpricenet: this.simpleAddValueCalculation(
                vehiclePriceNet,
                additionalsNet
            ),
        });
        this.listPriceNetChanged();
    }

    // nimmt den Wert aus "Fahrzeugpreis brutto" und "Sonderausstattung brutto" um "Listenpreis brutto" zu berechnen
    listPriceGrossCalculation() {
        const vehiclePriceGross = this.contractForm.get("vehiclepricegross")?.value;
        const additionalsGross = this.contractForm.get("additionalsgross")?.value;
        this.contractForm.patchValue({
            listpricegross: this.simpleAddValueCalculation(
                vehiclePriceGross,
                additionalsGross
            ),
        });
        this.listPriceGrossChanged();
    }

    // nimmt den Wert aus "Listenpreis netto" und "Nachlass netto" um "Hauspreis netto" zu berechnen
    housePriceNetCalculation() {
        const listPriceNet = this.contractForm.get("listpricenet")?.value;
        const discountNet = this.contractForm.get("discountnet")?.value;
        this.contractForm.patchValue({
            housepricenet: this.subtractValueCalculation(listPriceNet, discountNet),
        });
        this.housePriceNetChanged();
    }

    // nimmt den Wert aus "Listenpreis brutto" und "Nachlass brutto" um "Hauspreis brutto" zu berechnen
    housePriceGrossCalculation() {
        const listPriceGross = this.contractForm.get("listpricegross")?.value;
        const discountGross = this.contractForm.get("discountgross")?.value;
        this.contractForm.patchValue({
            housepricegross: this.subtractValueCalculation(
                listPriceGross,
                discountGross
            ),
        });
        this.housePriceGrossChanged();
    }

    // nimmt den Wert aus "Hauspreis netto", "Zubehör netto" und "Fracht netto" um "Endpreis netto" zu berechnen
    endPriceNetCalculation() {
        const housePriceNet = this.contractForm.get("housepricenet")?.value;
        const accessoiresNet = this.contractForm.get("accessoiresnet")?.value;
        const transferNet = this.contractForm.get("transfernet")?.value;
        this.contractForm.patchValue({
            endpricenet: this.addValueCalculation(
                housePriceNet,
                accessoiresNet,
                transferNet
            ),
        });
        this.endPriceNetChanged();
    }

    // nimmt den Wert aus "Hauspreis brutto", "Zubehör brutto" und "Fracht brutto" um "Endpreis brutto" zu berechnen
    endPriceGrossCalculation() {
        const housePriceGross = this.contractForm.get("housepricegross")?.value;
        const accessoiresGross = this.contractForm.get("accessoiresgross")?.value;
        const transferGross = this.contractForm.get("transfergross")?.value;
        this.contractForm.patchValue({
            endpricegross: this.addValueCalculation(
                housePriceGross,
                accessoiresGross,
                transferGross
            ),
        });
        this.endPriceGrossChanged();
    }

    // Addiert zwei Werte
    simpleAddValueCalculation(valueOne: number, valueTwo: number) {
        return valueOne + valueTwo;
    }

    // Addiert drei Werte
    addValueCalculation(valueOne: number, valueTwo: number, valueThree: number) {
        return valueOne + valueTwo + valueThree;
    }

    // Suptrahiert zwei Werte
    subtractValueCalculation(valueOne: number, valueTwo: number) {
        return valueOne - valueTwo;
    }

    // Netto zu brutto Berechnung
    netToGrossCalculation(netValue: number) {
        return netValue * 1.19;
    }

    // Brutto zu netto Berechnung
    grossToNetCalculation(grossValue: number) {
        return grossValue / 1.19;
    }

    checkState(state: boolean) {
        return state;
    }

    async save() {
        try {
            this.applyValuesForCreate();
            this.contractForm.disable();
            this.contractInformationsResponse = await this.contractService.createCarEntry(this.contractInformations);
            this.messageService.add({
                severity: 'success',
                summary: 'Erfolg',
                detail: 'Der Vertrag wurde erfolgreich angelegt.'
            });
            this.contractIsCreated = true;
        } catch (error) {
            this.contractIsCreated = false;
            this.messageService.add({
                severity: 'error',
                summary: 'Fehler',
                detail: 'Ihr vertrag konnte nicht erzeugt werden.',
            });
        }
    }

    async update() {
        try {
            this.applyValuesForUpdate();
            this.contractInformationsResponse = await this.contractService.updateCarEntry(this.contractInformations);
            this.messageService.add({
                severity: 'success',
                summary: 'Erfolg',
                detail: 'Der Vertrag wurde erfolgreich aktualisiert.'
            });
            setTimeout(() => {
                this.router.navigateByUrl("contract");
            }, 1000);

        } catch (error) {
            this.contractIsCreated = false;
            this.messageService.add({
                severity: 'error',
                summary: 'Fehler',
                detail: 'Ihr Vertrag konnte nicht aktualisiert werden.',
            });
        }
    }

    get f() {
        return this.contractForm.controls;
    }

    applyValuesForCreate() {
        const contractData = Object.assign(this.contractForm.value);
        this.contractInformations.contracttype = contractData.contracttype.value;
        this.contractInformations.vehicle = contractData.vehicle;
        this.contractInformations.buyersalutation = contractData.buyersalutation;
        this.contractInformations.buyerfirstname = contractData.buyerfirstname;
        this.contractInformations.buyerlastname = contractData.buyerlastname;
        this.contractInformations.buyerstreet = contractData.buyerstreet;
        this.contractInformations.buyerzip = contractData.buyerzip;
        this.contractInformations.buyercity = contractData.buyercity;
        this.contractInformations.buyerphone = contractData.buyerphone;
        this.contractInformations.buyermobile = contractData.buyermobile;
        this.contractInformations.buyeremail = contractData.buyeremail;
        this.contractInformations.brand = contractData.brand;
        this.contractInformations.equipment = contractData.equipment;
        this.contractInformations.engine = contractData.engine;
        this.contractInformations.color = contractData.color;
        this.contractInformations.interior = contractData.interior;
        this.contractInformations.tires = contractData.tires;
        this.contractInformations.contractdate = contractData.contractdate;
        this.contractInformations.deliverydate = contractData.deliverydate;
        this.contractInformations.vehiclepricenet = contractData.vehiclepricenet;
        this.contractInformations.vehiclepricegross = contractData.vehiclepricegross;
        this.contractInformations.additionalsnet = contractData.additionalsnet;
        this.contractInformations.additionalsgross = contractData.additionalsgross;
        this.contractInformations.listpricenet = contractData.listpricenet;
        this.contractInformations.listpricegross = contractData.listpricegross;
        this.contractInformations.discountnet = contractData.discountnet;
        this.contractInformations.discountgross = contractData.discountgross;
        this.contractInformations.housepricenet = contractData.housepricenet;
        this.contractInformations.housepricegross = contractData.housepricegross;
        this.contractInformations.transfernet = contractData.transfernet;
        this.contractInformations.transfergross = contractData.transfergross;
        this.contractInformations.endpricenet = contractData.endpricenet;
        this.contractInformations.endpricegross = contractData.endpricegross;
        this.contractInformations.paymenttype = contractData.paymenttype.value;
        this.contractInformations.precontractinformations = this.newContractInformations;
        this.contractInformations.formtype = FormType.DayAdmission;
        this.contractInformations.vatReportable = this.vatReportable;

        this.contractInformations.vin = contractData.vin
        this.contractInformations.mileage = contractData.mileage
        this.contractInformations.firstregistration = contractData.firstRegistration
        this.contractInformations.mainexaminationemissiontest = contractData.mainExaminationEmissionTest
        this.contractInformations.previousowner = contractData.previousOwner
    }

    applyValuesForUpdate() {
        const contractData = Object.assign(this.contractForm.value);
        this.contractInformations.id = contractData.id;
        this.contractInformations.contracttype = contractData.contracttype.value;
        this.contractInformations.vehicle = contractData.vehicle;
        this.contractInformations.buyersalutation = contractData.buyersalutation;
        this.contractInformations.buyerfirstname = contractData.buyerfirstname;
        this.contractInformations.buyerlastname = contractData.buyerlastname;
        this.contractInformations.buyerstreet = contractData.buyerstreet;
        this.contractInformations.buyerzip = contractData.buyerzip;
        this.contractInformations.buyercity = contractData.buyercity;
        this.contractInformations.buyerphone = contractData.buyerphone;
        this.contractInformations.buyermobile = contractData.buyermobile;
        this.contractInformations.buyeremail = contractData.buyeremail;
        this.contractInformations.brand = contractData.brand;
        this.contractInformations.equipment = contractData.equipment;
        this.contractInformations.engine = contractData.engine;
        this.contractInformations.color = contractData.color;
        this.contractInformations.interior = contractData.interior;
        this.contractInformations.tires = contractData.tires;
        this.contractInformations.contractdate = contractData.contractdate;
        this.contractInformations.deliverydate = contractData.deliverydate;
        this.contractInformations.vehiclepricenet = contractData.vehiclepricenet;
        this.contractInformations.vehiclepricegross = contractData.vehiclepricegross;
        this.contractInformations.additionalsnet = contractData.additionalsnet;
        this.contractInformations.additionalsgross = contractData.additionalsgross;
        this.contractInformations.listpricenet = contractData.listpricenet;
        this.contractInformations.listpricegross = contractData.listpricegross;
        this.contractInformations.discountnet = contractData.discountnet;
        this.contractInformations.discountgross = contractData.discountgross;
        this.contractInformations.housepricenet = contractData.housepricenet;
        this.contractInformations.housepricegross = contractData.housepricegross;
        this.contractInformations.transfernet = contractData.transfernet;
        this.contractInformations.transfergross = contractData.transfergross;
        this.contractInformations.endpricenet = contractData.endpricenet;
        this.contractInformations.endpricegross = contractData.endpricegross;
        this.contractInformations.paymenttype = contractData.paymenttype.value;
        this.contractInformations.precontractinformations = this.newContractInformations;
        this.contractInformations.formtype = FormType.DayAdmission;
        this.contractInformations.vatReportable = this.vatReportable;

        this.contractInformations.vin = contractData.vin
        this.contractInformations.mileage = contractData.mileage
        this.contractInformations.firstregistration = contractData.firstRegistration
        this.contractInformations.mainexaminationemissiontest = contractData.mainExaminationEmissionTest
        this.contractInformations.previousowner = contractData.previousOwner
    }

    patchValues(contract: Contract) {
        if (this.contractInformations) {
            if (contract.vatReportable) {
                this.taxselect = new FormGroup({
                    value: new FormControl('tax')
                  });

                  this.netgrossselect = new FormGroup({
                    value: new FormControl('net')
                });

             } else {

                this.taxselect = new FormGroup({
                    value: new FormControl('no-tax')
                  });

                  this.netgrossselect = new FormGroup({
                    value: new FormControl('gross')
                });
             }
            this.contractForm.patchValue({
                contracttype: contract.contracttype,
                vehicle: contract.vehicle,
                buyersalutation: contract.buyersalutation,
                buyerfirstname: contract.buyerfirstname,
                buyerlastname: contract.buyerlastname,
                buyerstreet: contract.buyerstreet,
                buyerzip: contract.buyerzip,
                buyercity: contract.buyercity,
                buyerphone: contract.buyerphone,
                buyermobile: contract.buyermobile,
                buyeremail: contract.buyeremail,
                brand: contract.brand,
                equipment: contract.equipment,
                engine: contract.engine,
                color: contract.color,
                interior: contract.interior,
                tires: contract.tires,
                contractdate: contract.contractdate,
                deliverydate: contract.deliverydate,
                vehiclepricenet: contract.vehiclepricenet,
                vehiclepricegross: contract.vehiclepricegross,
                additionalsnet: contract.additionalsnet,
                additionalsgross: contract.additionalsgross,
                listpricenet: contract.listpricenet,
                listpricegross: contract.listpricegross,
                discountnet: contract.discountnet,
                discountgross: contract.discountgross,
                housepricenet: contract.housepricenet,
                housepricegross: contract.housepricegross,
                transfernet: contract.transfernet,
                transfergross: contract.transfergross,
                endpricenet: contract.endpricenet,
                endpricegross: contract.endpricegross,
                paymenttype: contract.paymenttype,
                vatReportable: contract.vatReportable,
                formtype: contract.formtype,
                vin: contract.vin,
                mileage: contract.mileage,
                firstregistration: contract.firstregistration,
                mainexaminationemissiontest: contract.mainexaminationemissiontest,
                previousowner: contract.previousowner,
            });
            this.newContractInformations = contract.precontractinformations;
        }
    }


    async onDownloadReport(reportType: string) {
        try {
            switch (reportType) {
                case 'preContract':
                    this.loadingPreContract = true;
                    break;
                case 'contract':
                    this.loadingContract = true;
                    break;
                case 'attachment':
                    this.loadingAttachment = true;
                    break;
                case 'orderConfirmation':
                    this.loadingOrderConfirmation = true;
                    break;
                default:
                    console.error('Invalid report type:', reportType);
                    return;
            }

            if (reportType != null || reportType !== '') {
                if (this.contractId) {
                    this.contractInformationsResponse = this.contractInformations;
                }
                await this.contractService.downloadReport(this.contractInformationsResponse, reportType, true, true, true, this.isConnect, this.userDetail, 'used-car');
                this.messageService.add({
                    severity: 'success',
                    summary: 'Erfolg',
                    detail: 'Der Bericht wurde erfolgreich heruntergeladen.'
                });

            } else {
                console.error('Invalid value for download.');
                this.messageService.add({
                    severity: 'error',
                    summary: 'Fehler',
                    detail: 'Ungültiger Wert für den Download.',
                });
            }

        } catch (error) {
            console.error('Fehler beim Herunterladen des Berichts:', error);

        } finally {
            switch (reportType) {
                case 'preContract':
                    this.loadingPreContract = false;
                    break;
                case 'contract':
                    this.loadingContract = false;
                    break;
                case 'attachment':
                    this.loadingAttachment = false;
                    break;
                case 'orderConfirmation':
                    this.loadingOrderConfirmation = false;
                    break;
            }
        }
    }

    openSelectionPopup() {
        this.isAgreementSelectionVisible = true;
    }

    closeSelectionPopup() {
        this.isAgreementSelectionVisible = false;
    }

    closeAgreementSelectionPopup() {
        this.isAgreementSelectionVisible = false;
    }

    showPciEditPopup() {
        this.showPciEditField = true;
    }

    closePciEditPopup() {
        this.showPciEditField = false;
    }
}
