import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MessageService} from "primeng/api";
import {OrderConfirmationService} from "../../shared/service/orderConfirmationService";
import {Orderconfirmation} from "../../shared/models/orderconfirmation";
import {AuthService} from "../../auth/auth.service";

@Component({
  selector: 'app-new-orderconfirmation',
  templateUrl: './new-orderconfirmation.component.html',
  styleUrls: ['./new-orderconfirmation.component.scss']
})
export class NewOrderconfirmationComponent implements OnInit {
  orderconfirmationForm: FormGroup;
  activeState: boolean[] = [true, false, false];
  isAgreementVisible: boolean = false;
  vatReportable: boolean = false;
  grossPrice: boolean = false;

  contractDataType: any = [];
  contractIsCreated: boolean = false;
  styleDownloadFileCard = {background: '#f2f4f7', marginBottom: '2em'};
  orderConfirmation: Orderconfirmation = new Orderconfirmation();
  netgrossselect!: FormGroup;
  taxselect!: FormGroup;

  stateOptions: any[] = [
    { label: 'Netto', value: 'net' },
    { label: 'Brutto', value: 'gross' }
  ];

  taxOptions: any[] = [
    { label: 'MwSt. ausweisbar', value: 'tax' },
    { label: 'Nicht ausweisbar', value: 'no-tax' }
  ];

  constructor(private orderConfirmationService: OrderConfirmationService, private authService: AuthService, private messageService: MessageService) {

    this.orderconfirmationForm = new FormGroup({
      salutation: new FormControl("", Validators.required),
      firstName: new FormControl("", Validators.required),
      lastName: new FormControl("", Validators.required),
      street: new FormControl("", Validators.required),
      zip: new FormControl("", Validators.required),
      city: new FormControl("", Validators.required),
      phone: new FormControl(""),
      mobile: new FormControl("", Validators.required),
      email: new FormControl("", Validators.required),
      vehicle: new FormControl("", Validators.required),
      priceNet: new FormControl(0, Validators.required),
      priceGross: new FormControl(0, Validators.required),
      tax: new FormControl(0, Validators.required),
      vatReportable: new FormControl(false),
      orderConfirmationDate: new FormControl(this.getCurrentDate().toString(), Validators.required),
    });
  }

  async ngOnInit(): Promise<void> {
    this.orderconfirmationForm.controls['tax'].disable();

    this.netgrossselect = new FormGroup({
      value: new FormControl('net')
    });

    this.taxselect = new FormGroup({
      value: new FormControl('no-tax')
    });
  }

  toggle(index: number) {
    this.activeState[index] = !this.activeState[index];
  }

  getCurrentDate() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    return dd + '.' + mm + '.' + yyyy;
  }

  // Netto zu brutto Berechnung
  netToGrossCalculation(netValue: number) {
    return netValue * 1.19;
  }

  // Brutto zu netto Berechnung
  grossToNetCalculation(grossValue: number) {
    return grossValue / 1.19;
  }

  taxCalculation(netValue: number) {
    return netValue * 0.19;
  }

  // nimmt den Wert aus "Preis netto" und berechnet damit "Preis brutto"
  priceNetChanged() {
    const input = this.orderconfirmationForm.get("priceNet")?.value;
    if (input) {
      this.orderconfirmationForm.patchValue({
        priceGross: Number(this.netToGrossCalculation(input).toFixed(2)),
      });
    } else {
      this.orderconfirmationForm.patchValue({
        priceGross: Number((0).toFixed(2)),
      });
    }
    this.orderconfirmationForm.patchValue({
      tax: Number(this.taxCalculation(input).toFixed(2)),
    });
  }

  // nimmt den Wert aus "Preis brutto" und berechnet damit "Preis netto"
  priceGrossChanged() {
    const input = this.orderconfirmationForm.get("priceGross")?.value;
    if (input && this.vatReportable) {
      this.orderconfirmationForm.patchValue({
        priceNet: Number(this.grossToNetCalculation(input).toFixed(2)),
      });
    } else {
      this.orderconfirmationForm.patchValue({
        priceNet: Number((0).toFixed(2)),
      });
    }
    const netInput = this.orderconfirmationForm.get("priceNet")?.value;
    this.orderconfirmationForm.patchValue({
      tax: Number(this.taxCalculation(netInput).toFixed(2)),
    });
  }

  async save() {
    try {

      if (this.vatReportable) {
        this.orderConfirmation['priceNetText'] = true;
      } else {
        this.orderConfirmation['priceNetText'] = false;
      }
      await this.applyUpdatedReportValues();
      this.orderconfirmationForm.disable();
      await this.orderConfirmationService.createOrderConfirmation(this.orderConfirmation);
      await this.orderConfirmationService.downloadAndSendOrderConfirmation(this.orderConfirmation, true, true, true);
      this.messageService.add({
        severity: 'success',
        summary: 'Erfolg',
        detail: 'Die Auftragsbestätigung wurde erfolgreich angelegt.'
      });
      this.contractIsCreated = true;
    } catch (error) {
      this.contractIsCreated = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: 'Die Auftragsbestätigung konnte nicht erfolgreich gespeichert werden.',
      });
    }
  }

  downloadOrderConfirmation() {
    try {
      this.applyUpdatedReportValues();
        this.orderConfirmationService.downloadAndSendOrderConfirmation(this.orderConfirmation, true, false, false);
    } catch (error) {
      this.contractIsCreated = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Fehler',
        detail: 'Die Auftragsbestätigung konnte nicht erfolgreich erstellt werden.',
      });
    }
  }

  get f() {
    return this.orderconfirmationForm.controls;
  }

  async applyUpdatedReportValues() {
    const orderConfirmationForm = Object.assign(this.orderconfirmationForm.getRawValue());
    this.orderConfirmation.userId = this.authService.getUserIdFromToken();
    this.orderConfirmation.salutation = orderConfirmationForm.salutation;
    this.orderConfirmation.firstName = orderConfirmationForm.firstName;
    this.orderConfirmation.lastName = orderConfirmationForm.lastName;
    this.orderConfirmation.street = orderConfirmationForm.street;
    this.orderConfirmation.zip = orderConfirmationForm.zip;
    this.orderConfirmation.city = orderConfirmationForm.city;
    this.orderConfirmation.country = orderConfirmationForm.country;
    this.orderConfirmation.phone = orderConfirmationForm.phone;
    this.orderConfirmation.mobile = orderConfirmationForm.mobile;
    this.orderConfirmation.email = orderConfirmationForm.email;
    this.orderConfirmation.vehicle = orderConfirmationForm.vehicle;
    this.orderConfirmation.priceNet = Number(orderConfirmationForm.priceNet.toFixed(2));
    this.orderConfirmation.priceGross = Number(orderConfirmationForm.priceGross.toFixed(2));
    this.orderConfirmation.tax = Number(orderConfirmationForm.tax.toFixed(2));
    this.orderConfirmation.orderConfirmationDate = orderConfirmationForm.orderConfirmationDate;
    this.orderConfirmation.sellerFirstName = this.authService.getFirstNameFromToken();
    this.orderConfirmation.sellerLastName = this.authService.getLastNameFromToken();
    this.orderConfirmation.sellerEmailAddress = this.authService.getEmailFromToken();
  }

  changeNetGross(event: any) {
    let orderConfirmationDateFromForm = this.orderconfirmationForm.controls['orderConfirmationDate'].value;
    if (event.value === 'net') {
      this.orderconfirmationForm.controls['priceNet'].reset()
      this.orderconfirmationForm.controls['priceGross'].reset()
      this.orderconfirmationForm.controls['tax'].reset()
      this.enableNetValues();
      this.disableGrossValues();
    } else {
      this.orderconfirmationForm.controls['priceNet'].reset()
      this.orderconfirmationForm.controls['priceGross'].reset()
      this.orderconfirmationForm.controls['tax'].reset()
      this.enableGrossValues();
      this.disableNetValues();
    }
    this.orderconfirmationForm.patchValue({
      orderConfirmationDate: orderConfirmationDateFromForm
    });
  }

  changeTaxState(event: any) {
  let orderConfirmationDateFromForm = this.orderconfirmationForm.controls['orderConfirmationDate'].value;
  if (event.value === 'tax') {
    this.orderconfirmationForm.controls['priceGross'].reset();
    this.vatReportable = true;
    this.enableGrossValues();
    this.enableNetValues();
    this.orderConfirmation.vatReportable = true;
    this.orderconfirmationForm.updateValueAndValidity();
  } else if (event.value === 'no-tax') {
    this.vatReportable = false;
    this.enableGrossValues();
    this.disableNetValues();
    this.orderConfirmation.vatReportable = false;
    this.orderconfirmationForm.updateValueAndValidity();
  }

  this.orderconfirmationForm.patchValue({
    orderConfirmationDate: orderConfirmationDateFromForm
  });
}


  enableNetValues() {
    this.orderconfirmationForm.controls['priceNet'].enable();
  }

  disableNetValues() {
    this.orderconfirmationForm.controls['priceNet'].disable();
  }

  enableGrossValues() {
    this.orderconfirmationForm.controls['priceGross'].enable();
  }

  disableGrossValues() {
    this.orderconfirmationForm.controls['priceGross'].disable();
  }
}
