import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MessageService} from "primeng/api";
import { Priceconfirmation } from 'src/app/shared/models/priceconfirmation';
import { PriceConfirmationService } from 'src/app/shared/service/priceConfirmationService';

@Component({
  selector: 'app-price-confirmation',
  templateUrl: './price-confirmation.component.html',
  styleUrls: ['./price-confirmation.component.scss']
})
export class PriceConfirmationComponent implements OnInit {

    priceForm: FormGroup;
    priceConfirmation: Priceconfirmation = new Priceconfirmation();

    formTitle: string = 'Preisbestätigung';
    // btnLabel: string = 'Speichern';


    constructor(private priceConfirmationService: PriceConfirmationService,
                private messageService: MessageService,
               ) {

        this.priceForm = new FormGroup({
          vehicle: new FormControl("", Validators.required),
          numberPlate: new FormControl("", Validators.required),
          vin: new FormControl("", Validators.required),
          price: new FormControl("", Validators.required),
          date: new FormControl("", Validators.required),
        });
    }

    ngOnInit(): void {
      
    }

    async applyUpdatedReportValues() {
      const priceConfirmationForm = Object.assign(this.priceForm.getRawValue());
      this.priceConfirmation.date = priceConfirmationForm.date,
      this.priceConfirmation.vehicle = priceConfirmationForm.vehicle,
      this.priceConfirmation.numberPlate = priceConfirmationForm.numberPlate,
      this.priceConfirmation.vin = priceConfirmationForm.vin,
      this.priceConfirmation.price = priceConfirmationForm.price;
     
    }

    async generateReport() {
      try {
        await this.applyUpdatedReportValues();
        await this.priceConfirmationService.downloadAndSendpriceConfirmation(this.priceConfirmation);
        this.messageService.add({
          severity: 'success',
          summary: 'Erfolg',
          detail: 'Preisbestätigungsbericht erfolgreich generiert.'
        });
      } catch (error) {
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler',
          detail: 'Preisbestätigungsbericht wurde nicht generiert.',
        });
      }
    }

    get f() {
        return this.priceForm.controls;
    }

   
}
