<app-sidebar>
    <div class="custom-contract-card">
        <p-toast></p-toast>
        <p-confirmDialog #cdDeleteInformation [style]="{width: '50vw'}">
            <ng-template pTemplate="header">
                <h3>Möchten Sie den Eintrag löschen?</h3>
            </ng-template>
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="pi pi-times" label="Abbrechen"
                    (click)="cdDeleteInformation.reject()"></button>
                <button type="button" pButton icon="pi pi-check" label="Eintrag löschen"
                    (click)="cdDeleteInformation.accept()"></button>
            </ng-template>
        </p-confirmDialog>
        <p-tabView>
            <p-tabPanel header="Vereinbarungen">
                <div class="mt-5"></div>
                <p-toolbar>
                    <div class="col-12 flex align-items-center">
                        <p-button (click)="openInformations()" class="mr-2" icon="pi pi-plus"></p-button>
                        <h3>Vereinbarungen verwalten</h3>
                    </div>
                </p-toolbar>
                <p-table #informationstable [value]="modifiedInformationsData" dataKey="id" [loading]='loading'
                    styleClass=' p-datatable-striped' [globalFilterFields]="['id', 'content', 'description']">
                    <ng-template pTemplate="caption">
                        <div class="flex">
                            <p-dropdown [options]="templateTypes" [(ngModel)]="selectedType" [filter]="true"
                                filterBy="name" [showClear]="true" placeholder="Fahrzeugtyp suchen"
                                (onChange)="onTypeFilter()"></p-dropdown>

                            <span class="p-input-icon-left ml-2">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="applyFilterGlobal($event, 'contains', informationstable)"
                                    placeholder="Suchen" />
                            </span>
                            <button pButton label="Filter zurücksetzen" class="p-button-outlined ml-2"
                                icon="pi pi-filter-slash" (click)="clear(informationstable)"></button>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th width="20%">Typ</th>
                            <th width="30%">Inhalt</th>
                            <th width="30%">Hilfetext</th>
                            <th width="5%">Platzhalter</th>
                            <th width="5%">Standard</th>
                            <th width="5%"><!-- button --></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-data>
                        <tr [class]="data.placeholder ? 'placeholderrow' : ''">
                            <td [innerHTML]="data.templateType">
                                <!-- templateType -->
                            </td>
                            <td [innerHTML]="data.content">
                                <!-- content -->
                            </td>
                            <td [innerHTML]="data.description">
                                <!-- description -->
                            </td>
                            <td>
                                <span *ngIf="checkState(data.placeholder)">
                                    <i style="color: green" class="pi pi-check-circle"></i>
                                </span>
                            </td>
                            <td>
                                <span *ngIf="checkState(data.default)">
                                    <i style="color: green" class="pi pi-check-circle"></i>
                                </span>
                            </td>
                            <td>
                                <button (click)="openEdit(data)" pButton icon="pi pi-pencil"></button>
                                <button (click)="deleteEntry(data)" pButton icon="pi pi-trash"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-tabPanel>
        </p-tabView>
    </div>
    <form [formGroup]="informationsTemplateForm">
        <p-dialog [closable]="false" [modal]="true" [(visible)]="addInformationsTemplateVisible"
            [style]="{width: '80%', height: '100%'}">
            <p-toolbar>
                <div class="col-12 flex align-items-center">
                    <h3>Vereinbarung hinzufügen</h3>
                </div>
            </p-toolbar>
            <div class="mt-5"></div>
            <p-dropdown [showClear]="true" formControlName="templateType" [options]="templateTypes"
                placeholder="Bitte Fahrzeugtyp auswählen"></p-dropdown>
            <div class="mt-5"></div>
            <p-card header="Hilfetext der Vereinbarung">
                <p-editor formControlName="description" [style]="{ height: '200px' }"></p-editor>
            </p-card>
            <div class="mt-4"></div>
            <p-card header="Vereinbarung">
                <p-editor formControlName="content" [style]="{ height: '320px' }"></p-editor>
                <div class="mt-5">
                    <p-checkbox [binary]="true" formControlName="placeholder" inputId="placeholder"
                        label="Platzhalter"></p-checkbox>
                </div>
                <div class="mt-2">
                    <p-checkbox [binary]="true" formControlName="default" inputId="default"
                        label="als Standard festlegen"></p-checkbox>
                </div>
            </p-card>
            <ng-template pTemplate="footer">
                <div class="grid">
                    <div class="col-8"></div>
                    <div class="col-4">
                        <button type="button" (click)="addInformationsTemplate()" class="custom-save-button"
                            icon="pi pi-paperclip" label="Hinzufügen" pButton></button>
                        <button type="button" (click)="closeInformationsTemplateDialog()"
                            class="custom-save-button p-button-danger" icon="pi pi-times" label="Abbrechen"
                            pButton></button>
                    </div>
                </div>
            </ng-template>
        </p-dialog>
    </form>

    <form [formGroup]="informationsTemplateForm">
        <p-dialog [closable]="false" [modal]="true" [(visible)]="editInformationsTemplateVisible"
            [style]="{width: '80%', height: '100%'}">
            <p-toolbar>
                <div class="col-12 flex align-items-center">
                    <h3>Vereinbarung abändern</h3>
                </div>
            </p-toolbar>
            <div class="mt-5"></div>
            <p-dropdown [showClear]="true" formControlName="templateType" [options]="templateTypes"
                placeholder="Bitte Fahrzeugtyp auswählen"></p-dropdown>
            <div class="mt-5"></div>
            <p-card header="Hilfetext der Vereinbarung">
                <p-editor formControlName="description" [style]="{ height: '200px' }"></p-editor>
            </p-card>
            <div class="mt-4"></div>
            <p-card header="Vereinbarung">
                <p-editor formControlName="content" [style]="{ height: '320px' }"></p-editor>
                <div class="mt-5">
                    <p-checkbox [binary]="true" formControlName="placeholder" inputId="placeholder"
                        label="Platzhalter"></p-checkbox>
                </div>
                <div class="mt-2">
                    <p-checkbox [binary]="true" formControlName="default" inputId="default"
                        label="als Standard festlegen"></p-checkbox>
                </div>
            </p-card>
            <ng-template pTemplate="footer">
                <div class="grid">
                    <div class="col-8"></div>
                    <div class="col-4">
                        <button type="button" (click)="updateInformationsTemplate()" class="custom-save-button"
                            icon="pi pi-paperclip" label="Hinzufügen" pButton></button>
                        <button type="button" (click)="closeEditInformationsTemplateDialog()"
                            class="custom-save-button p-button-danger" icon="pi pi-times" label="Abbrechen"
                            pButton></button>
                    </div>
                </div>
            </ng-template>
        </p-dialog>
    </form>
</app-sidebar>