import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    navigateToOrderConfirmation() {
        this.router.navigateByUrl("dashboard/new-orderconfirmation");
    }

    navigateToNewCar() {
        this.router.navigateByUrl("dashboard/new-car");
    }

    navigateToUsedCar() {
        this.router.navigateByUrl("dashboard/used-car");
    }

    navigateToDayAdmission() {
        this.router.navigateByUrl("dashboard/day-admission");
    }

    navigateToNewCamper() {
        this.router.navigateByUrl("dashboard/new-camper");
    }

    navigateToUsedCamper() {
        this.router.navigateByUrl("dashboard/used-camper");
    }

    navigateToOldtimer() {
        this.router.navigateByUrl("dashboard/oldtimer")
    }

}
