import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import {AuthService} from "../auth.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  msgs: Message[] = [];
  // @ts-ignore
  loginForm: FormGroup;
  submitted = false;
  isloading = false;
  termsDialogVisible: boolean = false;
  privacyDialogVisible: boolean = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private authService: AuthService) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  get formControls(): any {
    return this.loginForm.controls;
  }

  async login(): Promise<void> {
    this.submitted = true;
    this.isloading = true;
    const credentials: any = Object.assign(this.loginForm.value);
     const ok = await this.authService.authenticate(credentials);
    if (ok) {
      this.isloading = false;
      await this.router.navigateByUrl('/dashboard/home');
    } else {
      this.isloading = false;
      this.msgs = [{ severity: 'error', summary: 'Error', detail: 'Ungültiger Benutzername oder Passwort' }];
    }
  }

  openTermsPopup() {
    this.termsDialogVisible = true;
  }

  openPrivacyPopup() {
    this.privacyDialogVisible = true;
  }
}

