<app-sidebar>
    <p-toast></p-toast>
    <form [formGroup]="priceForm">
        <div class="custom-contract-card">
            <p-card>
                <h2 class="mt-0">{{formTitle}}</h2>
                <div class="grid mt-5">

                    <div class="col-6 mt-4 p-fluid">
                        <span class="p-float-label">
                            <input formControlName="vehicle" pInputText type="text" />
                            <label>Fahrzeug</label>
                        </span>
                    </div>
                    <div class="col-6 mt-4 p-fluid">
                        <span class="p-float-label">
                            <input formControlName="numberPlate" pInputText type="text" />
                            <label>Kennzeichen</label>
                        </span>
                    </div>
                    <div class="col-6 mt-4 p-fluid">
                        <span class="p-float-label">
                            <input formControlName="vin" pInputText type="text" />
                            <label>Fahrgestellnummer</label>
                        </span>
                    </div>
                    <div class="col-6 mt-4 p-fluid">
                        <span class="p-float-label">
                            <input formControlName="price" pInputText type="text" />
                            <label>Preis</label>
                        </span>
                    </div>
                    <div class="col-6 mt-4 p-fluid">
                        <span class="p-float-label">
                            <p-inputMask formControlName="date" mask="99.99.9999"
                                placeholder="dd.mm.yyyy"></p-inputMask>
                            <label>Datum</label>
                        </span>
                    </div>
                </div>
                <p class="mt-4"></p>
                <p-toast></p-toast>
                <ng-template pTemplate="footer">
                    <div class="grid">
                        <div class="col-10"></div>
                        <div class="col-2">
                            <button (click)="generateReport()" class="custom-save-button"
                                icon="pi pi-save" label="Dokument erstellen" pButton type="submit"></button>
                        </div>
                    </div>
                </ng-template>
            </p-card>
        </div>
    </form>
</app-sidebar>
