import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from '../models/global.constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  get sidebarState() {
    if (GlobalConstants.sidebarActive) {
      return 'sidebarVisible';
    } else {
      return 'sidebarNotVisible';
    }
  }
}
